//
// 헤더 스타일
// --------------------------------------------------

/* 공통 헤더 */
.common-header {

	/* 주 메뉴 */
	.nav-main {
		box-sizing: border-box;
		height: 55px;
		text-align: center;
		font-size: 0;
		white-space: nowrap;
		border-top: 1px solid rgba(255,255,255,.2);
		position: relative;
		overflow: hidden;

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: calc(100% - 55px);
			background: #fff;
			position: absolute;
			top: 55px;
			left: 0;
		}
		
		.main {
			position: relative;

			> li {
				display: inline-block;
				vertical-align: top;

				&:not(:first-child) { margin-left: 45px; }
			}
		}

		.mm {
			display: block;
			height: 55px;
			line-height: 55px;
			text-align: left;
			font-size: 17px;
			color: #fff;
			text-decoration: none;
		}

		.sub {
			width: 100%;
			text-align: center;
			white-space: nowrap;
			color: #232323;
			position: absolute;
			top: 55px;
			left: 0;
			opacity: 0;
			z-index: -1;
			transition: all .2s;
		}

		.on .sub {
			opacity: 1;
			top: 55px;
			z-index: 10;
		}

		.sub-list {
			box-sizing: border-box;
			display: inline-block;
			width: 200px;
			padding: 20px 18px 30px;
			text-align: left;
			vertical-align: top;
			font-size: 14px;
			letter-spacing: -.025em;
			white-space: normal;
			position: relative;

			&:first-child:before {
				content: '';
				display: block;
				width: 1px;
				height: 9999px;
				background: #f0f0f0;
				position: absolute;
				top: 0;
				left: 0;
			}

			&:after {
				content: '';
				display: block;
				width: 1px;
				height: 9999px;
				background: #f0f0f0;
				position: absolute;
				top: 0;
				right: 0;
			}

			> li:not(:first-child) { padding-top: 5px; }
		}

		.sub-list2 {
			padding: 10px 3px 8px;
			white-space: normal;

			> li:not(:first-child) { padding-top: 7px; }
		}

		.sub-list3 {
			padding: 7px 3px 0 0;
			white-space: normal;

			> li:not(:first-child) { padding-top: 7px; }
		}

		.sm {
			display: block;
			height: 34px;
			line-height: 34px;
			padding: 0 3px;
			font-weight: 700;
			font-size: 16px;
			color: #111;
			text-decoration: none;
			border-bottom: 1px solid #cacdcf;
		}

		.sm2 {
			font-size: 14px;
			color: #666;
		}

		.sm3 {
			padding: 0 0 0 11px;
			font-size: 14px;
			color: #666;
			position: relative;

			&:before {
				content: '';
				display: block;
				width: 2px;
				height: 2px;
				background: #ccc;
				position: absolute;
				top: 10px;
				left: 5px;
			}
		}

		&.is-pyeongchang {
			.sm:hover, .sm:focus {
				color: #006097;
			}
			.sm2:hover, .sm2:focus {
				color: #006097;
			}
			.sm3:hover, .sm3:focus {
				color: #006097;
			}
		}

		&.is-jeju {
			.sm:hover, .sm:focus {
				color: #d8671a;
			}
			.sm2:hover, .sm2:focus {
				color: #d8671a;
			}
			.sm3:hover, .sm3:focus {
				color: #d8671a;
			}
		}
	}

	/* 통합예약 링크 */
	.reservation-link {
		position: absolute;
		top: 0;
		right: 0;

		a {
			display: block;
			height: 55px;
			line-height: 55px;
			padding: 0 25px;
			text-align: center;
			font-weight: 500;
			font-size: 16px;
			color: #fff;
			text-decoration: none;
			border-left: 1px solid rgba(255,255,255,.2);
			transition: all .4s;

			&:before {
				content: '';
				display: inline-block;
				margin-right: 10px;
				vertical-align: middle;
				position: relative;
				top: -2px;
				transition: all .4s;

				@include sprite($ico-header-reservation);
			}

			&:hover, &:focus {
				color: #fff !important;
				background: #131212;

				&:before { opacity: 1 !important; }
			}
		}
	}

	/* 스크롤 상태 */
	&.is-scroll {
		.nav-main {
			border-top: 0 none;

			.mm { font-size: 16px; color: rgba(255,255,255,.7); }
		}

		.reservation-link {
			a {
				color: rgba(255,255,255,.7);
				background: #131212;

				&:before { opacity: .7; }
			}
		}
	}

	/* 메뉴 활성 상태 */
	&.is-active {
		.nav-main {
			.mm {
				color: rgba(255,255,255,.7);
			}
			li.on .mm { color: rgba(255,255,255,1); }
		}
	}
}