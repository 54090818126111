//
// 페이지 하단 버튼 영역
// --------------------------------------------------

/* 페이지 하단 버튼 영역 */
.page-foot {
	margin-top: 50px;
	text-align: center;

	.btn-action + .btn-action {
		margin-left: 18px;
	}
}