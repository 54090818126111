//
// 썸네일 목록
// --------------------------------------------------

/* 썸네일 목록 */
.thumb-list {
	font-size: 0;
	
	.no-item {
		padding: 190px 0;
		border-bottom: 1px solid #d4d4d4;
		font-size: 18px;
		font-weight: 300;
		text-align: center;
	}

	.item {
		display: inline-block;
		width: 380px;
		vertical-align: top;

		&:not(:nth-child(3n+1)) { margin-left: 30px; }
		&:nth-child(3) ~ .item { margin-top: 40px; }
	}

	.full-link {
		display: block;
		width: 380px;
		text-decoration: none;
		border-bottom: 1px solid #ddd;
	}

	/* 링크 오버 */
	.full-link:hover,
	.full-link:focus {
		.thumb:after { opacity: 1; }
	}

	.thumb {
		width: 380px;
		height: 220px;
		position: relative;
		
		img { 
			width: 100%;
			height: 100%;
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(35,35,35,.6) url('@@img/com/thumb-list-hover.png') no-repeat 50% 50%;
			opacity: 0;
			transition: all .2s;
		}
	}
	
	.info {
		padding: 0 20px 29px;
		line-height: 1.3;
	}

	.cate {
		position: relative;
		box-sizing: border-box;
		display: inline-block;
		min-width: 67px;
		height: 30px;
		line-height: 30px;
		margin-top: -15px;
		padding: 0 14px 0;
		background-color: #000;
		color: #fff;
		font-size: 15px;
		font-weight: 300;
		text-align: center;
		letter-spacing: -.05em;

		&.type1 { background: #069; } /* 평창 */
		&.type2 { background: #d8671a; } /* 제주 */
		&.type3 { background: #b08a5d; }
	}

	.title {
		margin-top: 27px;
		color: #232323;
		font-size: 18px;
		font-weight: 500;
		letter-spacing: -.05em;
		line-height: 1.3;

		@include ellipsis;
	}

	.con, .desc {
		margin-top: 10px;
		font-weight: 300;
		font-size: 16px;
	}
}

/* 슬라이더 적용 타입 */
.thumb-list-slider-wrap {
	.bx-controls-direction { display: none; }
	.bx-controls { margin-top: 50px; }

	.bx-pager {
		width: 100%;
		text-align: center;
		font-size: 0;
		white-space: nowrap;
	}

	.bx-pager-item {
		display: inline-block;
		vertical-align: top;

		&:not(:first-child) { margin-left: 10px; }
	}

	.bx-pager-link {
		display: block;
		width: 10px;
		height: 10px;
		background: #999;
		border-radius: 5px;
		transition: all .4s;

		&.active { width: 30px; background: #006097; }
	}
	
	.site-jeju & .bx-pager-link.active { background: #d8671a; }
}
.thumb-list-slider {
	width: 100%;
	position: relative;
	overflow: hidden;

	.info {
		height: 120px;
		padding-bottom: 0;
	}

	.item {
		margin-left: 0 !important;
		margin-top: 0 !important;
	}
}