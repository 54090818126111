//
// 데이터 목록 테이블 스타일
// --------------------------------------------------

/* 데이터 목록 테이블 */
.data-list-table {
	width: 100%;
	table-layout: fixed;
	background-color: #fff;
	border: 0;
	font-size: 16px;

	th {
		box-sizing: border-box;
		height: 50px;
		background-color: #f5f5f5;
		border: 0;
		color: #666;
		font-size: 16px;
		font-weight: 500;
		text-align: center;
		word-wrap: break-word;
		border-top: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
	}

	td {
		box-sizing: border-box;
		height: 50px;
		padding: 9px 10px;
		border: 0 none;
		border-bottom: 1px solid #ddd;
		font-weight: 300;
		color: #232323;
		text-align: center;
		word-wrap: break-word;

		/* 정렬 */
		&.left { text-align: left; }
		&.right { text-align: right; }
	}

	tbody tr:not(:first-child) td {
		border-top: 1px solid #ddd;
	}

	.no-item {
		padding: 50px 0;
		text-align: center;
	}

	/* 제목 */
	.title-link {
		&.ellipsis {
			display: inline-block;
			max-width: 100%;
			vertical-align: middle;
			position: relative;

			@include ellipsis;
		}
	}

	.btn {
		height: 36px;
		line-height: 34px;
		padding: 0 15px;
	}

	/* size - medium */
	&.medium {
		th, td { height: 60px; }
	}
}