// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$ico-all-menu-name: 'ico-all-menu';
$ico-all-menu-x: 145px;
$ico-all-menu-y: 177px;
$ico-all-menu-offset-x: -145px;
$ico-all-menu-offset-y: -177px;
$ico-all-menu-width: 20px;
$ico-all-menu-height: 14px;
$ico-all-menu-total-width: 229px;
$ico-all-menu-total-height: 225px;
$ico-all-menu-image: '@@img/sprite/ico.png';
$ico-all-menu: (145px, 177px, -145px, -177px, 20px, 14px, 229px, 225px, '@@img/sprite/ico.png', 'ico-all-menu', );
$ico-arw-lang-name: 'ico-arw-lang';
$ico-arw-lang-x: 62px;
$ico-arw-lang-y: 206px;
$ico-arw-lang-offset-x: -62px;
$ico-arw-lang-offset-y: -206px;
$ico-arw-lang-width: 7px;
$ico-arw-lang-height: 4px;
$ico-arw-lang-total-width: 229px;
$ico-arw-lang-total-height: 225px;
$ico-arw-lang-image: '@@img/sprite/ico.png';
$ico-arw-lang: (62px, 206px, -62px, -206px, 7px, 4px, 229px, 225px, '@@img/sprite/ico.png', 'ico-arw-lang', );
$ico-blank-pop-close-name: 'ico-blank-pop-close';
$ico-blank-pop-close-x: 127px;
$ico-blank-pop-close-y: 115px;
$ico-blank-pop-close-offset-x: -127px;
$ico-blank-pop-close-offset-y: -115px;
$ico-blank-pop-close-width: 21px;
$ico-blank-pop-close-height: 21px;
$ico-blank-pop-close-total-width: 229px;
$ico-blank-pop-close-total-height: 225px;
$ico-blank-pop-close-image: '@@img/sprite/ico.png';
$ico-blank-pop-close: (127px, 115px, -127px, -115px, 21px, 21px, 229px, 225px, '@@img/sprite/ico.png', 'ico-blank-pop-close', );
$ico-box-plus-name: 'ico-box-plus';
$ico-box-plus-x: 0px;
$ico-box-plus-y: 0px;
$ico-box-plus-offset-x: 0px;
$ico-box-plus-offset-y: 0px;
$ico-box-plus-width: 48px;
$ico-box-plus-height: 48px;
$ico-box-plus-total-width: 229px;
$ico-box-plus-total-height: 225px;
$ico-box-plus-image: '@@img/sprite/ico.png';
$ico-box-plus: (0px, 0px, 0px, 0px, 48px, 48px, 229px, 225px, '@@img/sprite/ico.png', 'ico-box-plus', );
$ico-check-big-disabled-name: 'ico-check-big-disabled';
$ico-check-big-disabled-x: 102px;
$ico-check-big-disabled-y: 58px;
$ico-check-big-disabled-offset-x: -102px;
$ico-check-big-disabled-offset-y: -58px;
$ico-check-big-disabled-width: 24px;
$ico-check-big-disabled-height: 24px;
$ico-check-big-disabled-total-width: 229px;
$ico-check-big-disabled-total-height: 225px;
$ico-check-big-disabled-image: '@@img/sprite/ico.png';
$ico-check-big-disabled: (102px, 58px, -102px, -58px, 24px, 24px, 229px, 225px, '@@img/sprite/ico.png', 'ico-check-big-disabled', );
$ico-check-big-on-name: 'ico-check-big-on';
$ico-check-big-on-x: 141px;
$ico-check-big-on-y: 75px;
$ico-check-big-on-offset-x: -141px;
$ico-check-big-on-offset-y: -75px;
$ico-check-big-on-width: 24px;
$ico-check-big-on-height: 24px;
$ico-check-big-on-total-width: 229px;
$ico-check-big-on-total-height: 225px;
$ico-check-big-on-image: '@@img/sprite/ico.png';
$ico-check-big-on: (141px, 75px, -141px, -75px, 24px, 24px, 229px, 225px, '@@img/sprite/ico.png', 'ico-check-big-on', );
$ico-check-big-name: 'ico-check-big';
$ico-check-big-x: 141px;
$ico-check-big-y: 41px;
$ico-check-big-offset-x: -141px;
$ico-check-big-offset-y: -41px;
$ico-check-big-width: 24px;
$ico-check-big-height: 24px;
$ico-check-big-total-width: 229px;
$ico-check-big-total-height: 225px;
$ico-check-big-image: '@@img/sprite/ico.png';
$ico-check-big: (141px, 41px, -141px, -41px, 24px, 24px, 229px, 225px, '@@img/sprite/ico.png', 'ico-check-big', );
$ico-check-disabled-name: 'ico-check-disabled';
$ico-check-disabled-x: 30px;
$ico-check-disabled-y: 147px;
$ico-check-disabled-offset-x: -30px;
$ico-check-disabled-offset-y: -147px;
$ico-check-disabled-width: 20px;
$ico-check-disabled-height: 20px;
$ico-check-disabled-total-width: 229px;
$ico-check-disabled-total-height: 225px;
$ico-check-disabled-image: '@@img/sprite/ico.png';
$ico-check-disabled: (30px, 147px, -30px, -147px, 20px, 20px, 229px, 225px, '@@img/sprite/ico.png', 'ico-check-disabled', );
$ico-check-name: 'ico-check';
$ico-check-x: 150px;
$ico-check-y: 147px;
$ico-check-offset-x: -150px;
$ico-check-offset-y: -147px;
$ico-check-width: 20px;
$ico-check-height: 20px;
$ico-check-total-width: 229px;
$ico-check-total-height: 225px;
$ico-check-image: '@@img/sprite/ico.png';
$ico-check: (150px, 147px, -150px, -147px, 20px, 20px, 229px, 225px, '@@img/sprite/ico.png', 'ico-check', );
$ico-datepicker-next-name: 'ico-datepicker-next';
$ico-datepicker-next-x: 212px;
$ico-datepicker-next-y: 31px;
$ico-datepicker-next-offset-x: -212px;
$ico-datepicker-next-offset-y: -31px;
$ico-datepicker-next-width: 11px;
$ico-datepicker-next-height: 21px;
$ico-datepicker-next-total-width: 229px;
$ico-datepicker-next-total-height: 225px;
$ico-datepicker-next-image: '@@img/sprite/ico.png';
$ico-datepicker-next: (212px, 31px, -212px, -31px, 11px, 21px, 229px, 225px, '@@img/sprite/ico.png', 'ico-datepicker-next', );
$ico-datepicker-prev-name: 'ico-datepicker-prev';
$ico-datepicker-prev-x: 212px;
$ico-datepicker-prev-y: 62px;
$ico-datepicker-prev-offset-x: -212px;
$ico-datepicker-prev-offset-y: -62px;
$ico-datepicker-prev-width: 11px;
$ico-datepicker-prev-height: 21px;
$ico-datepicker-prev-total-width: 229px;
$ico-datepicker-prev-total-height: 225px;
$ico-datepicker-prev-image: '@@img/sprite/ico.png';
$ico-datepicker-prev: (212px, 62px, -212px, -62px, 11px, 21px, 229px, 225px, '@@img/sprite/ico.png', 'ico-datepicker-prev', );
$ico-default-pop-close-name: 'ico-default-pop-close';
$ico-default-pop-close-x: 96px;
$ico-default-pop-close-y: 115px;
$ico-default-pop-close-offset-x: -96px;
$ico-default-pop-close-offset-y: -115px;
$ico-default-pop-close-width: 21px;
$ico-default-pop-close-height: 21px;
$ico-default-pop-close-total-width: 229px;
$ico-default-pop-close-total-height: 225px;
$ico-default-pop-close-image: '@@img/sprite/ico.png';
$ico-default-pop-close: (96px, 115px, -96px, -115px, 21px, 21px, 229px, 225px, '@@img/sprite/ico.png', 'ico-default-pop-close', );
$ico-download-name: 'ico-download';
$ico-download-x: 58px;
$ico-download-y: 0px;
$ico-download-offset-x: -58px;
$ico-download-offset-y: 0px;
$ico-download-width: 39px;
$ico-download-height: 39px;
$ico-download-total-width: 229px;
$ico-download-total-height: 225px;
$ico-download-image: '@@img/sprite/ico.png';
$ico-download: (58px, 0px, -58px, 0px, 39px, 39px, 229px, 225px, '@@img/sprite/ico.png', 'ico-download', );
$ico-drone-name: 'ico-drone';
$ico-drone-x: 0px;
$ico-drone-y: 115px;
$ico-drone-offset-x: 0px;
$ico-drone-offset-y: -115px;
$ico-drone-width: 23px;
$ico-drone-height: 22px;
$ico-drone-total-width: 229px;
$ico-drone-total-height: 225px;
$ico-drone-image: '@@img/sprite/ico.png';
$ico-drone: (0px, 115px, 0px, -115px, 23px, 22px, 229px, 225px, '@@img/sprite/ico.png', 'ico-drone', );
$ico-family-site-off-name: 'ico-family-site-off';
$ico-family-site-off-x: 28px;
$ico-family-site-off-y: 206px;
$ico-family-site-off-offset-x: -28px;
$ico-family-site-off-offset-y: -206px;
$ico-family-site-off-width: 7px;
$ico-family-site-off-height: 4px;
$ico-family-site-off-total-width: 229px;
$ico-family-site-off-total-height: 225px;
$ico-family-site-off-image: '@@img/sprite/ico.png';
$ico-family-site-off: (28px, 206px, -28px, -206px, 7px, 4px, 229px, 225px, '@@img/sprite/ico.png', 'ico-family-site-off', );
$ico-family-site-on-name: 'ico-family-site-on';
$ico-family-site-on-x: 45px;
$ico-family-site-on-y: 206px;
$ico-family-site-on-offset-x: -45px;
$ico-family-site-on-offset-y: -206px;
$ico-family-site-on-width: 7px;
$ico-family-site-on-height: 4px;
$ico-family-site-on-total-width: 229px;
$ico-family-site-on-total-height: 225px;
$ico-family-site-on-image: '@@img/sprite/ico.png';
$ico-family-site-on: (45px, 206px, -45px, -206px, 7px, 4px, 229px, 225px, '@@img/sprite/ico.png', 'ico-family-site-on', );
$ico-guide-link-name: 'ico-guide-link';
$ico-guide-link-x: 190px;
$ico-guide-link-y: 177px;
$ico-guide-link-offset-x: -190px;
$ico-guide-link-offset-y: -177px;
$ico-guide-link-width: 5px;
$ico-guide-link-height: 8px;
$ico-guide-link-total-width: 229px;
$ico-guide-link-total-height: 225px;
$ico-guide-link-image: '@@img/sprite/ico.png';
$ico-guide-link: (190px, 177px, -190px, -177px, 5px, 8px, 229px, 225px, '@@img/sprite/ico.png', 'ico-guide-link', );
$ico-header-banner-close-name: 'ico-header-banner-close';
$ico-header-banner-close-x: 141px;
$ico-header-banner-close-y: 0px;
$ico-header-banner-close-offset-x: -141px;
$ico-header-banner-close-offset-y: 0px;
$ico-header-banner-close-width: 31px;
$ico-header-banner-close-height: 31px;
$ico-header-banner-close-total-width: 229px;
$ico-header-banner-close-total-height: 225px;
$ico-header-banner-close-image: '@@img/sprite/ico.png';
$ico-header-banner-close: (141px, 0px, -141px, 0px, 31px, 31px, 229px, 225px, '@@img/sprite/ico.png', 'ico-header-banner-close', );
$ico-header-reservation-name: 'ico-header-reservation';
$ico-header-reservation-x: 33px;
$ico-header-reservation-y: 115px;
$ico-header-reservation-offset-x: -33px;
$ico-header-reservation-offset-y: -115px;
$ico-header-reservation-width: 22px;
$ico-header-reservation-height: 22px;
$ico-header-reservation-total-width: 229px;
$ico-header-reservation-total-height: 225px;
$ico-header-reservation-image: '@@img/sprite/ico.png';
$ico-header-reservation: (33px, 115px, -33px, -115px, 22px, 22px, 229px, 225px, '@@img/sprite/ico.png', 'ico-header-reservation', );
$ico-info-name: 'ico-info';
$ico-info-x: 90px;
$ico-info-y: 147px;
$ico-info-offset-x: -90px;
$ico-info-offset-y: -147px;
$ico-info-width: 20px;
$ico-info-height: 20px;
$ico-info-total-width: 229px;
$ico-info-total-height: 225px;
$ico-info-image: '@@img/sprite/ico.png';
$ico-info: (90px, 147px, -90px, -147px, 20px, 20px, 229px, 225px, '@@img/sprite/ico.png', 'ico-info', );
$ico-inner-download-name: 'ico-inner-download';
$ico-inner-download-x: 116px;
$ico-inner-download-y: 177px;
$ico-inner-download-offset-x: -116px;
$ico-inner-download-offset-y: -177px;
$ico-inner-download-width: 19px;
$ico-inner-download-height: 16px;
$ico-inner-download-total-width: 229px;
$ico-inner-download-total-height: 225px;
$ico-inner-download-image: '@@img/sprite/ico.png';
$ico-inner-download: (116px, 177px, -116px, -177px, 19px, 16px, 229px, 225px, '@@img/sprite/ico.png', 'ico-inner-download', );
$ico-inner-drone-on-name: 'ico-inner-drone-on';
$ico-inner-drone-on-x: 58px;
$ico-inner-drone-on-y: 177px;
$ico-inner-drone-on-offset-x: -58px;
$ico-inner-drone-on-offset-y: -177px;
$ico-inner-drone-on-width: 19px;
$ico-inner-drone-on-height: 18px;
$ico-inner-drone-on-total-width: 229px;
$ico-inner-drone-on-total-height: 225px;
$ico-inner-drone-on-image: '@@img/sprite/ico.png';
$ico-inner-drone-on: (58px, 177px, -58px, -177px, 19px, 18px, 229px, 225px, '@@img/sprite/ico.png', 'ico-inner-drone-on', );
$ico-inner-drone-name: 'ico-inner-drone';
$ico-inner-drone-x: 87px;
$ico-inner-drone-y: 177px;
$ico-inner-drone-offset-x: -87px;
$ico-inner-drone-offset-y: -177px;
$ico-inner-drone-width: 19px;
$ico-inner-drone-height: 18px;
$ico-inner-drone-total-width: 229px;
$ico-inner-drone-total-height: 225px;
$ico-inner-drone-image: '@@img/sprite/ico.png';
$ico-inner-drone: (87px, 177px, -87px, -177px, 19px, 18px, 229px, 225px, '@@img/sprite/ico.png', 'ico-inner-drone', );
$ico-inner-hole-on-name: 'ico-inner-hole-on';
$ico-inner-hole-on-x: 182px;
$ico-inner-hole-on-y: 59px;
$ico-inner-hole-on-offset-x: -182px;
$ico-inner-hole-on-offset-y: -59px;
$ico-inner-hole-on-width: 19px;
$ico-inner-hole-on-height: 19px;
$ico-inner-hole-on-total-width: 229px;
$ico-inner-hole-on-total-height: 225px;
$ico-inner-hole-on-image: '@@img/sprite/ico.png';
$ico-inner-hole-on: (182px, 59px, -182px, -59px, 19px, 19px, 229px, 225px, '@@img/sprite/ico.png', 'ico-inner-hole-on', );
$ico-inner-hole-name: 'ico-inner-hole';
$ico-inner-hole-x: 182px;
$ico-inner-hole-y: 88px;
$ico-inner-hole-offset-x: -182px;
$ico-inner-hole-offset-y: -88px;
$ico-inner-hole-width: 19px;
$ico-inner-hole-height: 19px;
$ico-inner-hole-total-width: 229px;
$ico-inner-hole-total-height: 225px;
$ico-inner-hole-image: '@@img/sprite/ico.png';
$ico-inner-hole: (182px, 88px, -182px, -88px, 19px, 19px, 229px, 225px, '@@img/sprite/ico.png', 'ico-inner-hole', );
$ico-inner-print-on-name: 'ico-inner-print-on';
$ico-inner-print-on-x: 182px;
$ico-inner-print-on-y: 30px;
$ico-inner-print-on-offset-x: -182px;
$ico-inner-print-on-offset-y: -30px;
$ico-inner-print-on-width: 19px;
$ico-inner-print-on-height: 19px;
$ico-inner-print-on-total-width: 229px;
$ico-inner-print-on-total-height: 225px;
$ico-inner-print-on-image: '@@img/sprite/ico.png';
$ico-inner-print-on: (182px, 30px, -182px, -30px, 19px, 19px, 229px, 225px, '@@img/sprite/ico.png', 'ico-inner-print-on', );
$ico-inner-print-name: 'ico-inner-print';
$ico-inner-print-x: 182px;
$ico-inner-print-y: 146px;
$ico-inner-print-offset-x: -182px;
$ico-inner-print-offset-y: -146px;
$ico-inner-print-width: 19px;
$ico-inner-print-height: 19px;
$ico-inner-print-total-width: 229px;
$ico-inner-print-total-height: 225px;
$ico-inner-print-image: '@@img/sprite/ico.png';
$ico-inner-print: (182px, 146px, -182px, -146px, 19px, 19px, 229px, 225px, '@@img/sprite/ico.png', 'ico-inner-print', );
$ico-inner-vr-on-name: 'ico-inner-vr-on';
$ico-inner-vr-on-x: 0px;
$ico-inner-vr-on-y: 177px;
$ico-inner-vr-on-offset-x: 0px;
$ico-inner-vr-on-offset-y: -177px;
$ico-inner-vr-on-width: 19px;
$ico-inner-vr-on-height: 19px;
$ico-inner-vr-on-total-width: 229px;
$ico-inner-vr-on-total-height: 225px;
$ico-inner-vr-on-image: '@@img/sprite/ico.png';
$ico-inner-vr-on: (0px, 177px, 0px, -177px, 19px, 19px, 229px, 225px, '@@img/sprite/ico.png', 'ico-inner-vr-on', );
$ico-inner-vr-name: 'ico-inner-vr';
$ico-inner-vr-x: 29px;
$ico-inner-vr-y: 177px;
$ico-inner-vr-offset-x: -29px;
$ico-inner-vr-offset-y: -177px;
$ico-inner-vr-width: 19px;
$ico-inner-vr-height: 19px;
$ico-inner-vr-total-width: 229px;
$ico-inner-vr-total-height: 225px;
$ico-inner-vr-image: '@@img/sprite/ico.png';
$ico-inner-vr: (29px, 177px, -29px, -177px, 19px, 19px, 229px, 225px, '@@img/sprite/ico.png', 'ico-inner-vr', );
$ico-link-arw-name: 'ico-link-arw';
$ico-link-arw-x: 175px;
$ico-link-arw-y: 177px;
$ico-link-arw-offset-x: -175px;
$ico-link-arw-offset-y: -177px;
$ico-link-arw-width: 5px;
$ico-link-arw-height: 8px;
$ico-link-arw-total-width: 229px;
$ico-link-arw-total-height: 225px;
$ico-link-arw-image: '@@img/sprite/ico.png';
$ico-link-arw: (175px, 177px, -175px, -177px, 5px, 8px, 229px, 225px, '@@img/sprite/ico.png', 'ico-link-arw', );
$ico-location-gt-name: 'ico-location-gt';
$ico-location-gt-x: 158px;
$ico-location-gt-y: 115px;
$ico-location-gt-offset-x: -158px;
$ico-location-gt-offset-y: -115px;
$ico-location-gt-width: 6px;
$ico-location-gt-height: 11px;
$ico-location-gt-total-width: 229px;
$ico-location-gt-total-height: 225px;
$ico-location-gt-image: '@@img/sprite/ico.png';
$ico-location-gt: (158px, 115px, -158px, -115px, 6px, 11px, 229px, 225px, '@@img/sprite/ico.png', 'ico-location-gt', );
$ico-location-home-name: 'ico-location-home';
$ico-location-home-x: 212px;
$ico-location-home-y: 117px;
$ico-location-home-offset-x: -212px;
$ico-location-home-offset-y: -117px;
$ico-location-home-width: 11px;
$ico-location-home-height: 15px;
$ico-location-home-total-width: 229px;
$ico-location-home-total-height: 225px;
$ico-location-home-image: '@@img/sprite/ico.png';
$ico-location-home: (212px, 117px, -212px, -117px, 11px, 15px, 229px, 225px, '@@img/sprite/ico.png', 'ico-location-home', );
$ico-map-pin-name: 'ico-map-pin';
$ico-map-pin-x: 212px;
$ico-map-pin-y: 0px;
$ico-map-pin-offset-x: -212px;
$ico-map-pin-offset-y: 0px;
$ico-map-pin-width: 17px;
$ico-map-pin-height: 21px;
$ico-map-pin-total-width: 229px;
$ico-map-pin-total-height: 225px;
$ico-map-pin-image: '@@img/sprite/ico.png';
$ico-map-pin: (212px, 0px, -212px, 0px, 17px, 21px, 229px, 225px, '@@img/sprite/ico.png', 'ico-map-pin', );
$ico-page-first-name: 'ico-page-first';
$ico-page-first-x: 212px;
$ico-page-first-y: 184px;
$ico-page-first-offset-x: -212px;
$ico-page-first-offset-y: -184px;
$ico-page-first-width: 11px;
$ico-page-first-height: 11px;
$ico-page-first-total-width: 229px;
$ico-page-first-total-height: 225px;
$ico-page-first-image: '@@img/sprite/ico.png';
$ico-page-first: (212px, 184px, -212px, -184px, 11px, 11px, 229px, 225px, '@@img/sprite/ico.png', 'ico-page-first', );
$ico-page-last-name: 'ico-page-last';
$ico-page-last-x: 212px;
$ico-page-last-y: 163px;
$ico-page-last-offset-x: -212px;
$ico-page-last-offset-y: -163px;
$ico-page-last-width: 11px;
$ico-page-last-height: 11px;
$ico-page-last-total-width: 229px;
$ico-page-last-total-height: 225px;
$ico-page-last-image: '@@img/sprite/ico.png';
$ico-page-last: (212px, 163px, -212px, -163px, 11px, 11px, 229px, 225px, '@@img/sprite/ico.png', 'ico-page-last', );
$ico-page-next-name: 'ico-page-next';
$ico-page-next-x: 102px;
$ico-page-next-y: 92px;
$ico-page-next-offset-x: -102px;
$ico-page-next-offset-y: -92px;
$ico-page-next-width: 7px;
$ico-page-next-height: 11px;
$ico-page-next-total-width: 229px;
$ico-page-next-total-height: 225px;
$ico-page-next-image: '@@img/sprite/ico.png';
$ico-page-next: (102px, 92px, -102px, -92px, 7px, 11px, 229px, 225px, '@@img/sprite/ico.png', 'ico-page-next', );
$ico-page-prev-name: 'ico-page-prev';
$ico-page-prev-x: 119px;
$ico-page-prev-y: 92px;
$ico-page-prev-offset-x: -119px;
$ico-page-prev-offset-y: -92px;
$ico-page-prev-width: 7px;
$ico-page-prev-height: 11px;
$ico-page-prev-total-width: 229px;
$ico-page-prev-total-height: 225px;
$ico-page-prev-image: '@@img/sprite/ico.png';
$ico-page-prev: (119px, 92px, -119px, -92px, 7px, 11px, 229px, 225px, '@@img/sprite/ico.png', 'ico-page-prev', );
$ico-photo-next-inverse-name: 'ico-photo-next-inverse';
$ico-photo-next-inverse-x: 107px;
$ico-photo-next-inverse-y: 0px;
$ico-photo-next-inverse-offset-x: -107px;
$ico-photo-next-inverse-offset-y: 0px;
$ico-photo-next-inverse-width: 24px;
$ico-photo-next-inverse-height: 47px;
$ico-photo-next-inverse-total-width: 229px;
$ico-photo-next-inverse-total-height: 225px;
$ico-photo-next-inverse-image: '@@img/sprite/ico.png';
$ico-photo-next-inverse: (107px, 0px, -107px, 0px, 24px, 47px, 229px, 225px, '@@img/sprite/ico.png', 'ico-photo-next-inverse', );
$ico-photo-next-name: 'ico-photo-next';
$ico-photo-next-x: 0px;
$ico-photo-next-y: 58px;
$ico-photo-next-offset-x: 0px;
$ico-photo-next-offset-y: -58px;
$ico-photo-next-width: 24px;
$ico-photo-next-height: 47px;
$ico-photo-next-total-width: 229px;
$ico-photo-next-total-height: 225px;
$ico-photo-next-image: '@@img/sprite/ico.png';
$ico-photo-next: (0px, 58px, 0px, -58px, 24px, 47px, 229px, 225px, '@@img/sprite/ico.png', 'ico-photo-next', );
$ico-photo-prev-inverse-name: 'ico-photo-prev-inverse';
$ico-photo-prev-inverse-x: 34px;
$ico-photo-prev-inverse-y: 58px;
$ico-photo-prev-inverse-offset-x: -34px;
$ico-photo-prev-inverse-offset-y: -58px;
$ico-photo-prev-inverse-width: 24px;
$ico-photo-prev-inverse-height: 47px;
$ico-photo-prev-inverse-total-width: 229px;
$ico-photo-prev-inverse-total-height: 225px;
$ico-photo-prev-inverse-image: '@@img/sprite/ico.png';
$ico-photo-prev-inverse: (34px, 58px, -34px, -58px, 24px, 47px, 229px, 225px, '@@img/sprite/ico.png', 'ico-photo-prev-inverse', );
$ico-photo-prev-name: 'ico-photo-prev';
$ico-photo-prev-x: 68px;
$ico-photo-prev-y: 58px;
$ico-photo-prev-offset-x: -68px;
$ico-photo-prev-offset-y: -58px;
$ico-photo-prev-width: 24px;
$ico-photo-prev-height: 47px;
$ico-photo-prev-total-width: 229px;
$ico-photo-prev-total-height: 225px;
$ico-photo-prev-image: '@@img/sprite/ico.png';
$ico-photo-prev: (68px, 58px, -68px, -58px, 24px, 47px, 229px, 225px, '@@img/sprite/ico.png', 'ico-photo-prev', );
$ico-plane-name: 'ico-plane';
$ico-plane-x: 0px;
$ico-plane-y: 206px;
$ico-plane-offset-x: 0px;
$ico-plane-offset-y: -206px;
$ico-plane-width: 18px;
$ico-plane-height: 19px;
$ico-plane-total-width: 229px;
$ico-plane-total-height: 225px;
$ico-plane-image: '@@img/sprite/ico.png';
$ico-plane: (0px, 206px, 0px, -206px, 18px, 19px, 229px, 225px, '@@img/sprite/ico.png', 'ico-plane', );
$ico-radio-disabled-name: 'ico-radio-disabled';
$ico-radio-disabled-x: 182px;
$ico-radio-disabled-y: 0px;
$ico-radio-disabled-offset-x: -182px;
$ico-radio-disabled-offset-y: 0px;
$ico-radio-disabled-width: 20px;
$ico-radio-disabled-height: 20px;
$ico-radio-disabled-total-width: 229px;
$ico-radio-disabled-total-height: 225px;
$ico-radio-disabled-image: '@@img/sprite/ico.png';
$ico-radio-disabled: (182px, 0px, -182px, 0px, 20px, 20px, 229px, 225px, '@@img/sprite/ico.png', 'ico-radio-disabled', );
$ico-radio-name: 'ico-radio';
$ico-radio-x: 0px;
$ico-radio-y: 147px;
$ico-radio-offset-x: 0px;
$ico-radio-offset-y: -147px;
$ico-radio-width: 20px;
$ico-radio-height: 20px;
$ico-radio-total-width: 229px;
$ico-radio-total-height: 225px;
$ico-radio-image: '@@img/sprite/ico.png';
$ico-radio: (0px, 147px, 0px, -147px, 20px, 20px, 229px, 225px, '@@img/sprite/ico.png', 'ico-radio', );
$ico-radio2-name: 'ico-radio2';
$ico-radio2-x: 120px;
$ico-radio2-y: 147px;
$ico-radio2-offset-x: -120px;
$ico-radio2-offset-y: -147px;
$ico-radio2-width: 20px;
$ico-radio2-height: 20px;
$ico-radio2-total-width: 229px;
$ico-radio2-total-height: 225px;
$ico-radio2-image: '@@img/sprite/ico.png';
$ico-radio2: (120px, 147px, -120px, -147px, 20px, 20px, 229px, 225px, '@@img/sprite/ico.png', 'ico-radio2', );
$ico-state-invalid-name: 'ico-state-invalid';
$ico-state-invalid-x: 212px;
$ico-state-invalid-y: 93px;
$ico-state-invalid-offset-x: -212px;
$ico-state-invalid-offset-y: -93px;
$ico-state-invalid-width: 14px;
$ico-state-invalid-height: 14px;
$ico-state-invalid-total-width: 229px;
$ico-state-invalid-total-height: 225px;
$ico-state-invalid-image: '@@img/sprite/ico.png';
$ico-state-invalid: (212px, 93px, -212px, -93px, 14px, 14px, 229px, 225px, '@@img/sprite/ico.png', 'ico-state-invalid', );
$ico-state-valid-name: 'ico-state-valid';
$ico-state-valid-x: 212px;
$ico-state-valid-y: 142px;
$ico-state-valid-offset-x: -212px;
$ico-state-valid-offset-y: -142px;
$ico-state-valid-width: 15px;
$ico-state-valid-height: 11px;
$ico-state-valid-total-width: 229px;
$ico-state-valid-total-height: 225px;
$ico-state-valid-image: '@@img/sprite/ico.png';
$ico-state-valid: (212px, 142px, -212px, -142px, 15px, 11px, 229px, 225px, '@@img/sprite/ico.png', 'ico-state-valid', );
$ico-system-pop-close-name: 'ico-system-pop-close';
$ico-system-pop-close-x: 65px;
$ico-system-pop-close-y: 115px;
$ico-system-pop-close-offset-x: -65px;
$ico-system-pop-close-offset-y: -115px;
$ico-system-pop-close-width: 21px;
$ico-system-pop-close-height: 21px;
$ico-system-pop-close-total-width: 229px;
$ico-system-pop-close-total-height: 225px;
$ico-system-pop-close-image: '@@img/sprite/ico.png';
$ico-system-pop-close: (65px, 115px, -65px, -115px, 21px, 21px, 229px, 225px, '@@img/sprite/ico.png', 'ico-system-pop-close', );
$ico-video-name: 'ico-video';
$ico-video-x: 60px;
$ico-video-y: 147px;
$ico-video-offset-x: -60px;
$ico-video-offset-y: -147px;
$ico-video-width: 20px;
$ico-video-height: 20px;
$ico-video-total-width: 229px;
$ico-video-total-height: 225px;
$ico-video-image: '@@img/sprite/ico.png';
$ico-video: (60px, 147px, -60px, -147px, 20px, 20px, 229px, 225px, '@@img/sprite/ico.png', 'ico-video', );
$ico-vr-name: 'ico-vr';
$ico-vr-x: 182px;
$ico-vr-y: 117px;
$ico-vr-offset-x: -182px;
$ico-vr-offset-y: -117px;
$ico-vr-width: 19px;
$ico-vr-height: 19px;
$ico-vr-total-width: 229px;
$ico-vr-total-height: 225px;
$ico-vr-image: '@@img/sprite/ico.png';
$ico-vr: (182px, 117px, -182px, -117px, 19px, 19px, 229px, 225px, '@@img/sprite/ico.png', 'ico-vr', );
$ico-width: 229px;
$ico-height: 225px;
$ico-image: '@@img/sprite/ico.png';
$ico-sprites: ($ico-all-menu, $ico-arw-lang, $ico-blank-pop-close, $ico-box-plus, $ico-check-big-disabled, $ico-check-big-on, $ico-check-big, $ico-check-disabled, $ico-check, $ico-datepicker-next, $ico-datepicker-prev, $ico-default-pop-close, $ico-download, $ico-drone, $ico-family-site-off, $ico-family-site-on, $ico-guide-link, $ico-header-banner-close, $ico-header-reservation, $ico-info, $ico-inner-download, $ico-inner-drone-on, $ico-inner-drone, $ico-inner-hole-on, $ico-inner-hole, $ico-inner-print-on, $ico-inner-print, $ico-inner-vr-on, $ico-inner-vr, $ico-link-arw, $ico-location-gt, $ico-location-home, $ico-map-pin, $ico-page-first, $ico-page-last, $ico-page-next, $ico-page-prev, $ico-photo-next-inverse, $ico-photo-next, $ico-photo-prev-inverse, $ico-photo-prev, $ico-plane, $ico-radio-disabled, $ico-radio, $ico-radio2, $ico-state-invalid, $ico-state-valid, $ico-system-pop-close, $ico-video, $ico-vr, );
$ico: (229px, 225px, '@@img/sprite/ico.png', $ico-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
