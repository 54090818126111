//
// 헤더 스타일
// --------------------------------------------------

/* 공통 헤더 */
.common-header {

	/* 주 메뉴 : 전체 드롭 스타일 */
	.nav-main-full {
		box-sizing: border-box;
		height: 55px;
		text-align: center;
		font-size: 0;
		white-space: nowrap;
		border-top: 1px solid rgba(255,255,255,.2);
		position: relative;
		overflow: hidden;

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: calc(100% - 55px);
			background: #fff;
			position: absolute;
			top: 55px;
			left: 0;
		}
		
		.main {
			position: relative;

			> li {
				display: inline-block;
				vertical-align: top;
				position: relative;

				&:first-child .sub:before {
					content: '';
					display: block;
					width: 1px;
					height: 9999px;
					background: #f0f0f0;
					position: absolute;
					top: 55px;
					left: 0;
				}
			}
		}

		.mm {
			display: block;
			height: 55px;
			line-height: 55px;
			padding: 0 50px;
			text-align: left;
			font-size: 18px;
			color: #fff;
			text-decoration: none;
		}

		.sub {
			padding: 20px 18px 30px;

			&:after {
				content: '';
				display: block;
				width: 1px;
				height: 9999px;
				background: #f0f0f0;
				position: absolute;
				top: 55px;
				right: 0;
			}

			> li {
				text-align: left;
				&:not(:first-child) { padding-top: 5px; }
			}
		}

		.sub2 {
			padding: 10px 3px 8px;
			white-space: normal;

			> li:not(:first-child) { padding-top: 7px; }
		}

		.sm {
			display: block;
			height: 34px;
			line-height: 34px;
			padding: 0 3px;
			font-weight: 700;
			font-size: 16px;
			color: #111;
			text-decoration: none;
			border-bottom: 1px solid #cacdcf;

			&:hover, &:focus {
				color: #b0895c;
			}
		}

		.sm2 {
			font-size: 14px;
			color: #666;

			&:hover, &:focus {
				color: #b0895c;
			}
		}
	}

	/* 스크롤 상태 */
	&.is-scroll {
		.nav-main-full {
			border-top: 0 none;

			.mm { font-size: 16px; color: rgba(255,255,255,.7); }
		}
	}

	/* 메뉴 활성 상태 */
	&.is-active {
		.nav-main-full {
			.mm {
				color: rgba(255,255,255,.7);
				&:hover, &:focus { color: rgba(255,255,255,1); }
			}
		}
	}
}