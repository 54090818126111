//
// 공통 기본정보
// --------------------------------------------------

/* 공통 기본정보 */
.common-basic-info {
	box-sizing: border-box;
	display: table;
	width: 100%;
	table-layout: fixed;
	border: 1px solid #ddd;

	.info {
		box-sizing: border-box;
		display: table-cell;
		padding: 30px 50px;
		vertical-align: top;
	}

	.btn-area {
		margin-top: 20px;
		padding-top: 20px;
		text-align: center;
		border-top: 1px solid #ddd;

		.btn { padding: 0 40px; }
		.btn + .btn { margin-left: 3px; }
	}

	.info2 {
		box-sizing: border-box;
		display: table-cell;
		width: 440px;
		padding: 30px 50px;
		vertical-align: top;
		background: #76777b;

		.title {
			margin-bottom: 10px;
			font-weight: 500;
			font-size: 20px;
			color: #fff;
			letter-spacing: -.05em;
		}

		.list {
			li {
				padding-left: 14px;
				line-height: 1.3;
				font-weight: 300;
				font-size: 15px;
				color: rgba(255,255,255,.8);
				position: relative;

				&:not(:first-child) { margin-top: 10px; }

				&:before {
					content: '';
					display: block;
					width: 3px;
					height: 3px;
					background: #fff;
					position: absolute;
					top: 7px;
					left: 4px;
				}
			}
		}
	}

	.info-head {
		margin-bottom: 10px;
		font-size: 20px;
		color: #232323;
		letter-spacing: -.05em;

		&:not(:first-child) {
			margin-top: 30px;
		}
	}

	.dot-bu-list {
		font-size: 16px;

		li:before {
			background: #666;
			top: 11px;
			left: 0;
		}
	}
}