//
// 콘트롤 버튼
// --------------------------------------------------

/* 콘트롤 버튼 */
.btn-control {
	display: inline-block;
	height: 38px;
	line-height: 38px;
	vertical-align: middle;
	padding: 0 23px 0 23px;
	font-size: 16px;
	color: #fff;
	letter-spacing: 0;
	background: rgba(0,0,0,.6);
	border-radius: 19px;
	transition: background .2s;
	overflow: hidden;

	[class^='ico'] {
		display: inline-block;
		margin-right: 7px;
		vertical-align: middle;
		position: relative;
		top: -2px;
	}

	.vr { @include sprite($ico-vr); }
	.video { @include sprite($ico-video); }
	.plane { @include sprite($ico-plane); }
	.drone { @include sprite($ico-drone); }
	.map { @include sprite($ico-map-pin); }

	&:hover, &:focus {
		background: rgba(0,0,0,.8);

		span:not(.ico) {
			position: relative;
			top: 0;
			left: 0;
		}
	}
}