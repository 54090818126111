//
// 본문
// --------------------------------------------------

/* 본문 내용 - 공통 - 가운데 정렬 */
.common-content {
	width: $content-width;
	margin-left: auto;
	margin-right: auto;
}

/* 현재 페이지 경로 */
.common-page-location {
	width: $content-width;
	height: 52px;
	margin: 0 auto 50px;
	line-height: 52px;
	font-size: 0;
	white-space: nowrap;
	position: relative;

	@include clearfix;

	> span {
		display: inline-block;
		height: 100%;
		padding: 0 20px;
		vertical-align: middle;
		font-size: 15px;
		color: #232323;
		letter-spacing: -.025em;
	}

	.home {
		padding: 0;
		font-size: 0;
		
		&:before {
			content: '';
			display: inline-block;
			margin-right: 20px;
			vertical-align: middle;
			position: relative;
			top: 1px;

			@include sprite($ico-location-home);
		}
	}

	.gt {
		display: inline-block;
		min-width: auto;
		padding: 0;
		vertical-align: middle;
		position: relative;
		top: 1px;

		@include spriteIR($ico-location-gt);
	}
}