//
// 기본 버튼
// --------------------------------------------------

/* 기본 버튼 */
.btn {
	$height: 40px;
	$padding-lr: 24px;

	box-sizing: border-box;
	display: inline-block;
	height: $height;
	line-height: $height - 2;
	padding: 0 $padding-lr;
	vertical-align: middle;
	text-align: center;
	font-family: 'Noto Sans KR', sans-serif;
	font-weight: 400;
	font-size: 16px;
	color: #fff;
	white-space: nowrap;
	background: #999;
	border: 1px solid #999;
	border-radius: 0;
	cursor: pointer;
	transition: color .2s, background .2s, border .2s;

	&:hover, &:focus { background-color: #888; }

	/* button 태그 클릭 시 텍스트 밀림 제거 */
	&:focus span,
	&:active span {
		position: relative;
		top: 0;
		left: 0;
	}

	// primary
	&.primary {
		color: #fff;
		background: #232323;
		border-color: #232323;
		
		&:hover, &:focus { background-color: #000; }
	}

	// 라운드 타입
	&.round {
		border-radius: $height / 2;
	}

	// 보더타입
	&.light {
		color: #888;
		background: #fff;
		border-color: #999;
		
		// &:hover, &:focus { background-color: #fafafa; }
		&:hover, &:focus { color: #fff; background-color: #999; }

		&.primary {
			color: #232323;
			border-color: #232323;

			// &:hover, &:focus { background-color: #fafafa; }
			&:hover, &:focus { color: #fff; background-color: #000; }
		}
	}

	// 사이즈
	// small
	&.small {
		$height: 34px;
		$padding-lr: 15px;

		height: $height;
		line-height: $height;
		padding: 0 $padding-lr;
		font-size: 15px;

		// 라운드 타입
		&.round {
			border-radius: $height / 2;
		}
	}
	// medium
	&.medium {
		$height: 46px;
		$padding-lr: 29px;

		height: $height;
		line-height: $height;
		padding: 0 $padding-lr;

		// 라운드 타입
		&.round {
			border-radius: $height / 2;
		}
	}
	// large
	&.large {
		$height: 50px;
		$padding-lr: 40px;

		height: $height;
		line-height: $height;
		padding: 0 $padding-lr;

		// 라운드 타입
		&.round {
			border-radius: $height / 2;
		}
	}

	/* 버튼 내부 아이콘 */
	.inner-ico {
		display: inline-block;
		margin-right: 5px;
		vertical-align: middle;
		position: relative;

		&.print { top: -1px; @include sprite($ico-inner-print); }
		&.download { top: -2px; @include sprite($ico-inner-download); }
		&.hole { top: -2px; @include sprite($ico-inner-hole); }
		&.drone { top: -2px; @include sprite($ico-inner-drone); }
		&.vr { top: -2px; @include sprite($ico-inner-vr); }
	}
	/* 버튼 내부 아이콘 - 반전 컬러 */
	&.light.primary:hover,
	&.light.primary:focus {
		.inner-ico {
			&.print { @include sprite-position($ico-inner-print-on); }
			&.hole { @include sprite-position($ico-inner-hole-on); }
			&.drone { @include sprite-position($ico-inner-drone-on); }
			&.vr { @include sprite-position($ico-inner-vr-on); }
		}
	}
}

a.btn { text-decoration: none !important; }