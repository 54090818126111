//
// 섹션
// --------------------------------------------------

/* 본문 섹션 마진 */
.next-section {
    margin-top: 60px;
}
/* 본문 섹션 마진2 */
.next-section2 {
    margin-top: 30px;
}

/* 본문 섹션 마진 - 팝업 */
.next-section-pop {
    margin-top: 22px;
}