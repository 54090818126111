/* 요소 간격 */
.el-before { margin-right: 7px; }
.el-after { margin-left: 7px; }

/* 인풋 유형 */
.txt {

	/* 인풋 유효성 아이콘 */
	&.is-valid {
		padding-right: 40px;
		background-image: url('@@img/com/input-valid.png');
		background-repeat: no-repeat;
		background-position: 100% 50%;
	}
	&.is-invalid {
		padding-right: 40px;
		background-image: url('@@img/com/input-invalid.png');
		background-repeat: no-repeat;
		background-position: 100% 50%;
	}

	/* date-picker */
	&.date-picker,
	&.ico-date-picker {
		padding-right: 40px;
		background-image: url('@@img/com/input-datepicker.png');
		background-repeat: no-repeat;
		background-position: 100% 50%;

		&[disabled] { background-image: url('@@img/com/input-datepicker-disabled.png'); }
	}
}

/* 텍스트-버튼 인풋 */
.input-txt-btn {
	display: inline-block;
	width: 100%;
	vertical-align: middle;
	font-size: 0;
	white-space: nowrap;

	.txt {
		width: calc(100% - 127px) !important;
		margin-right: 10px;
	}

	.btn {
		width: 117px;
		padding: 0;
	}

	&.file-input {
		.txt[readonly] {
			background-color: #fff;
		}
	}
}

/* 전화 입력 인풋 */
.input-tel-wrap {
	display: inline-block;
	width: 100%;
	vertical-align: middle;
	font-size: 0;
	white-space: nowrap;

	.txt,
	.select {
		width: calc((100% - 60px) / 3);
	}

	.line {
		display: inline-block;
		width: 30px;
		text-align: center;
		line-height: 1;
		vertical-align: middle;
		font-size: 16px;
		color: #666;
		position: relative;
		top: -2px;
	}
}

/* 텍스트-버튼 인풋 */
.input-tel-btn {
	display: inline-block;
	vertical-align: middle;
	font-size: 0;
	white-space: nowrap;

	.input-tel-wrap {
		display: inline-block;
		width: calc(100% - 157px);
		margin-right: 10px;
	}

	.btn {
		width: 147px;
		padding: 0;
	}
}

/* from-to 타입 */
.input-from-to {
	display: inline-block;
	width: 100%;
	vertical-align: middle;
	font-size: 0;
	white-space: nowrap;

	.txt,
	.select {
		display: inline-block;
		width: calc((100% - 30px) / 2);
	}

	.line {
		display: inline-block;
		width: 30px;
		vertical-align: middle;
		text-align: center;
		font-weight: 300;
		font-size: 16px;
		color: #666;
	}
}

/* 생년월일 입력 인풋 */
.input-birth-ymd {
	display: inline-block;
	width: 100%;
	vertical-align: middle;
	font-size: 0;
	white-space: nowrap;

	.txt,
	.select {
		width: calc((100% - 20px) / 3);

		&:not(:first-child) {
			margin-left: 10px;
		}
	}
}

/* 이메일 입력 인풋 */
.input-email-wrap {
	display: inline-block;
	width: 100%;
	vertical-align: middle;
	font-size: 0;
	white-space: nowrap;

	.txt {
		width: calc((100% - 40px) / 3);
	}

	.select {
		width: calc((100% - 40px) / 3);
		margin-left: 10px;
	}

	.at {
		display: inline-block;
		width: 30px;
		line-height: 1;
		vertical-align: middle;
		text-align: center;
		font-weight: 300;
		font-size: 16px;
		color: #666;
		position: relative;
		top: -2px;
	}
}

/* 주소입력 */
.input-address-wrap {
	display: inline-block;
	width: 100%;
	vertical-align: middle;
	position: relative;

	.input-address { display: block; }
	.input-address:not(:first-child) { margin-top: 10px; }
	.input-address:first-child {
		padding-right: 157px;
		position: relative;

		.txt { width: 100%; }
		.btn-find-address {
			width: 147px;
			padding: 0;
			position: absolute;
			top: 0;
			right: 0;
		}
	}
}

/* 하위 분류 선택 */
.sub-depth-select {
	display: inline-block;
	width: 100%;
	vertical-align: middle;
	font-size: 0;
	white-space: nowrap;

	&[data-len='2'] .select { width: calc((100% - 10px) / 2); }
	&[data-len='3'] .select { width: calc((100% - 20px) / 3); }
	.select:not(:first-child) { margin-left: 10px; }
}

/* 학교정보 입력 인풋 */
.input-school-info {
	display: inline-block;
	width: 100%;
	vertical-align: middle;
	font-size: 0;
	white-space: nowrap;

	.select {
		width: calc((100% - 10px) / 2);

		&:first-child { margin-right: 10px; }
	}
}

/* 옵션 인풋 리스트 */
.option-input-list {
	.option-item {
		line-height: 40px;

		@include clearfix;

		&:not(:first-child) { margin-top: 10px; }
	}

	.opt {
		float: left;
		width: 195px;

		.txt { margin-right: 15px; }

		label,
		.select { vertical-align: top; }
	}

	.price {
		float: right;
	}
}

/* 옵션 인풋 리스트2 */
.option-input-list2 {
	max-height: 350px;
	padding-right: 20px;
	overflow: auto;
	overflow-x: hidden;

	.option-item {
		line-height: 40px;

		@include clearfix;

		&:not(:first-child) { margin-top: 10px; }
	}

	.opt {
		float: left;
		width: calc(100% - 200px);
	}

	.opt2 {
		float: right;
		width: 200px;
		text-align: right;

		label,
		.select { vertical-align: top; }
	}
}