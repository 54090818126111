//
// 게시판 목록
// --------------------------------------------------

/* 게시판 목록 */
.board-list {
	width: 100%;
	font-weight: 300;
	font-size: 16px;
	letter-spacing: -.05em;
	color: #666;
	background: #fff;
	border: 0 none;
	border-top: 1px solid #232323;
	border-bottom: 1px solid #e7e7e7;
	table-layout: fixed;

	&.type2 thead th {
		background: #f5f5f5;
	}

	thead {
		th {
			height: 49px;
			text-align: center;
			font-weight: 500;
			color: #232323;
			border: 0 none;
			border-bottom: 1px solid #ddd;
		}
	}

	tbody {
		td {
			box-sizing: border-box;
			height: 50px;
			padding: 7px 0;
			text-align: center;
			border: 0 none;
		}

		tr:not(:first-child) td {
			border-top: 1px solid #ddd;
		}
		
	}
	
	.strong td {
		background: #f9f9f9;
	}

	.no-item td {
		padding: 150px 0;
		text-align: center;
	}

	.title {
		padding: 0 20px;
		text-align: left;
	}

	.title-link {
		&.ellipsis {
			display: inline-block;
			max-width: 100%;
			vertical-align: middle;
			position: relative;

			@include ellipsis;
		}
	}

	.title-link:hover,
	.title-link:focus {
		text-decoration: underline;
		color: $point;
	}
}