//
// 게시판 목록 상단
// --------------------------------------------------

/* 게시판 목록 상단 */
.board-list-header {
	line-height: 40px;
	margin-bottom: 10px;

	@include clearfix;

	.count {
		display: inline-block;
		margin-bottom: -8px;
		padding-left: 5px;
		color: #232323;
		font-size: 16px;
		font-weight: 400;
		vertical-align: bottom;

		.current { color: #b0895c; }
	}
}