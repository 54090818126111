//
// 사각 기본 탭메뉴
// --------------------------------------------------

/* 사각 기본 탭메뉴 */
.tab-rect {
	margin-bottom: 30px;
	position: relative;

	&:before {
		content: '';
		display: block;
		width: 100%;
		height: 1px;
		background: #111;
		position: absolute;
		bottom: 0;
		left: 0;
	}

	@include clearfix;

	/* 마진 없이 사용 */
	&.no-margin { margin-bottom: 0; }

	/* full 스타일 */
	&.is-full {
		@for $i from 2 through 10 {
			&[data-len='#{$i}'] {
				.tab {
					width: calc(100% / #{$i});
					&:not(:first-child) { width: calc(100% / #{$i} + 1px); }
				}
			}
		}

		a {
			min-width: auto;
			padding: 0;
		}
	}

	a {
		box-sizing: border-box;
		display: block;
		min-width: 143px;
		height: 48px;
		line-height: 46px;
		padding: 0 20px;
		text-align: center;
		font-size: 16px;
		color: #999;
		text-decoration: none;
		border: 1px solid #ddd;
		border-bottom: 1px solid #111;
	}

	.tab {
		float: left;

		&:not(:first-child) { margin-left: -1px; }
		
		&.selected {
			position: relative;
			z-index: 1;

			a {
				font-weight: 500;
				color: #232323;
				border: 1px solid #111;
				border-bottom: 1px solid #fff;
			}
		}
	}
}