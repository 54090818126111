//
// 상품 목록
// --------------------------------------------------

/* 상품 목록 - 썸네일형 */
.product-list {
	&.deco {
		border-top: 1px solid #232323;
	}

	.no-item {
		padding: 80px 0;
		text-align: center;
		font-weight: 300;
		font-size: 18px;
		color: #232323;
		border-bottom: 1px solid #ddd;
	}

	.item {
		display: table;
		width: 100%;
		padding: 30px 0;
		border-bottom: 1px solid #ddd;
	}

	.thumb {
		display: table-cell;
		width: 320px;
		height: 200px;
		vertical-align: top;

		.img {
			width: 100%;
			height: 100%;
		}

		a {
			display: block;
			position: relative;

			&:after {
				content: '';
				display: block;
				position: absolute;
				bottom: 0;
				right: 0;

				@include sprite($ico-box-plus);
			}
		}
	}

	.con-wrap {
		display: table-cell;
		padding-left: 50px;
		vertical-align: middle;
	}

	.title {
		line-height: 1.2;
		font-weight: 500;
		font-size: 24px;
		color: #232323;
	}

	.info {
		margin-top: 18px;
		font-weight: 300;
		font-size: 16px;
		color: #666;

		> li {
			padding-left: 12px;
			position: relative;

			&:before {
				content: '';
				display: block;
				width: 3px;
				height: 3px;
				margin-top: -1px;
				background: #b4b4b4;
				position: absolute;
				top: 50%;
				left: 0;
			}

			&:not(:first-child) { margin-top: 4px; }
		}
	}

	.more-info {
		margin-top: 22px;
	}

	.detail-link {
		display: inline-block;
		padding-right: 17px;
		font-size: 15px;
		color: #232323;
		letter-spacing: -.05em;
		text-decoration: none;
		border-bottom: 1px solid #232323;
		position: relative;

		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 8px;
			right: 3px;

			@include sprite($ico-link-arw);
		}
	}

	.con-wrap2 {
		box-sizing: border-box;
		display: table-cell;
		width: 270px;
		padding-right: 10px;
		vertical-align: middle;
		text-align: right;
	}

	.item > .btn-area {
		box-sizing: border-box;
		display: table-cell;
		width: 160px;
		padding-right: 10px;
		text-align: right;
		vertical-align: middle;
	}

	.price-info {
		margin-bottom: 4px;
		font-size: 16px;
		color: #666;
	}

	.price-wrap {
		display: inline-block;
		line-height: 1.1;
		font-size: 16px;
		color: #666;

		& + .btn-area { margin-top: 22px; }
	}

	.price-tit {
		display: table-cell;
		text-align: right;
		font-size: 16px;
	}

	.before-price {
		display: table-row;
		font-size: 0;
		white-space: nowrap;

		.price-val {
			display: table-cell;
			padding-left: 20px;
			font-size: 22px;
			color: #232323;
			text-decoration: line-through;
		}

		> span {
			padding-top: 5px;
			padding-bottom: 8px;
		}
	}

	.now-price {
		display: table-row;
		font-size: 0;
		white-space: nowrap;

		.price-val {
			display: table-cell;
			padding-left: 20px;
			font-size: 22px;
			font-weight: 700;
			color: #232323;
		}

		.num {
			font-weight: 700;
			font-size: 32px;
			color: #d14e40;
		}
	}

	.help-msg {
		margin-top: 15px;
		width: inherit;
		font-weight: 300;
		color: #666;
		white-space: nowrap;

		@include clearfix;

		p { float: right; }
	}
}