//
// VR보기 탭메뉴
// --------------------------------------------------

/* VR보기 탭메뉴 */
.tab-vr {
	display: inline-block;
	height: 40px;
	line-height: 40px;
	font-size: 0;
	white-space: nowrap;
	background: rgba(35,35,35,.8);
	
	.vr-view & {
		position: absolute;
		top: 20px;
		left: 50%;
		transform: translate(-50%,0);
	}

	.tab {
		display: inline-block;
		vertical-align: top;
		position: relative;

		&.selected a {
			color: #fff;
		}

		&:not(:first-child):before {
			content: '';
			display: block;
			width: 1px;
			height: 10px;
			background: rgba(255,255,255,.5);
			position: absolute;
			top: calc(50% - 5px);
			left: 0;
		}
	}

	a {
		display: block;
		padding: 0 25px;
		font-weight: 300;
		font-size: 15px;
		color: rgba(255,255,255,.7);
		text-decoration: none;
	}
}