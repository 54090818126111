//
// 레이아웃 스타일
// --------------------------------------------------

/* 스킵 내비게이션 */
.skip-navi {
	@include hidden;

	&:focus {
		display: block;
		width: 100%;
		height: 40px;
		line-height: 40px;
		text-align: center;
		font-size: 16px;
		color: #fff;
		text-decoration: none;
		background: $main-color;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99999;
	}
}

/* 레이아웃 컨테이너 */
.wrapper {
	box-sizing: border-box;
	width: 100%;
	min-width: $site-min-width;
	min-height: 100%;
	padding: 0 0 calc(200px + 120px) 0;
	position: relative;

	&.show-banner { padding-top: 100px; }
}