//
// Heading
// --------------------------------------------------

/* 서브 타이틀 */
.head-sub {
	margin-bottom: 18px;
	padding-left: 5px;
	line-height: 1.2;
	font-weight: 500;
	font-size: 24px;
	color: #232323;
	letter-spacing: -.05em;

	.desc {
		margin-left: 7px;
		font-weight: 300;
		font-size: 17px;
		color: #666;
	}

	&-info {
		margin-top: -40px;
		margin-bottom: 16px;
		padding-right: 5px;
		text-align: right;
		font-weight: 300;
		font-size: 16px;
	}

	&-btn {
		margin-top: -49px;
		margin-bottom: 15px;
		text-align: right;
		font-weight: 300;
		font-size: 16px;

		.btn + .btn { margin-left: 5px; }
	}
}

/* 서브 타이틀2 */
.head-sub2 {
	margin-bottom: 10px;
	padding-left: 5px;
	line-height: 1.2;
	font-weight: 400;
	font-size: 20px;
	color: #232323;
	letter-spacing: -.05em;

	.desc {
		margin-left: 7px;
		font-weight: 300;
		font-size: 15px;
		color: #666;
	}

	.head-sub + & { padding-top: 7px; }

	&-info {
		margin-top: -33px;
		margin-bottom: 10px;
		padding-right: 5px;
		text-align: right;
		font-weight: 300;
		font-size: 15px;
	}
}

/* 서브 타이틀3 */
.head-sub3 {
	margin-bottom: 10px;
	padding-left: 5px;
	line-height: 1.2;
	font-weight: 500;
	font-size: 16px;
	color: #232323;
	letter-spacing: -.05em;

	.head-sub + & { padding-top: 7px; }
}

/* 서브 타이틀 - 팝업 */
.head-sub-pop {
	margin-bottom: 11px;
	padding-left: 5px;
	line-height: 1.2;
	font-weight: 500;
	font-size: 18px;
	color: #232323;
	letter-spacing: -.05em;

	.desc {
		font-weight: 300;
		font-size: 14px;
		color: #666;
	}
}