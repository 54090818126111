// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$waterpark-arrow-bottom-name: 'waterpark-arrow-bottom';
$waterpark-arrow-bottom-x: 458px;
$waterpark-arrow-bottom-y: 119px;
$waterpark-arrow-bottom-offset-x: -458px;
$waterpark-arrow-bottom-offset-y: -119px;
$waterpark-arrow-bottom-width: 23px;
$waterpark-arrow-bottom-height: 15px;
$waterpark-arrow-bottom-total-width: 497px;
$waterpark-arrow-bottom-total-height: 445px;
$waterpark-arrow-bottom-image: '@@img/sprite/waterpark.png';
$waterpark-arrow-bottom: (458px, 119px, -458px, -119px, 23px, 15px, 497px, 445px, '@@img/sprite/waterpark.png', 'waterpark-arrow-bottom', );
$waterpark-arrow-right-name: 'waterpark-arrow-right';
$waterpark-arrow-right-x: 458px;
$waterpark-arrow-right-y: 65px;
$waterpark-arrow-right-offset-x: -458px;
$waterpark-arrow-right-offset-y: -65px;
$waterpark-arrow-right-width: 26px;
$waterpark-arrow-right-height: 44px;
$waterpark-arrow-right-total-width: 497px;
$waterpark-arrow-right-total-height: 445px;
$waterpark-arrow-right-image: '@@img/sprite/waterpark.png';
$waterpark-arrow-right: (458px, 65px, -458px, -65px, 26px, 44px, 497px, 445px, '@@img/sprite/waterpark.png', 'waterpark-arrow-right', );
$waterpark-attention1-name: 'waterpark-attention1';
$waterpark-attention1-x: 390px;
$waterpark-attention1-y: 325px;
$waterpark-attention1-offset-x: -390px;
$waterpark-attention1-offset-y: -325px;
$waterpark-attention1-width: 55px;
$waterpark-attention1-height: 55px;
$waterpark-attention1-total-width: 497px;
$waterpark-attention1-total-height: 445px;
$waterpark-attention1-image: '@@img/sprite/waterpark.png';
$waterpark-attention1: (390px, 325px, -390px, -325px, 55px, 55px, 497px, 445px, '@@img/sprite/waterpark.png', 'waterpark-attention1', );
$waterpark-attention10-name: 'waterpark-attention10';
$waterpark-attention10-x: 130px;
$waterpark-attention10-y: 390px;
$waterpark-attention10-offset-x: -130px;
$waterpark-attention10-offset-y: -390px;
$waterpark-attention10-width: 55px;
$waterpark-attention10-height: 55px;
$waterpark-attention10-total-width: 497px;
$waterpark-attention10-total-height: 445px;
$waterpark-attention10-image: '@@img/sprite/waterpark.png';
$waterpark-attention10: (130px, 390px, -130px, -390px, 55px, 55px, 497px, 445px, '@@img/sprite/waterpark.png', 'waterpark-attention10', );
$waterpark-attention11-name: 'waterpark-attention11';
$waterpark-attention11-x: 65px;
$waterpark-attention11-y: 390px;
$waterpark-attention11-offset-x: -65px;
$waterpark-attention11-offset-y: -390px;
$waterpark-attention11-width: 55px;
$waterpark-attention11-height: 55px;
$waterpark-attention11-total-width: 497px;
$waterpark-attention11-total-height: 445px;
$waterpark-attention11-image: '@@img/sprite/waterpark.png';
$waterpark-attention11: (65px, 390px, -65px, -390px, 55px, 55px, 497px, 445px, '@@img/sprite/waterpark.png', 'waterpark-attention11', );
$waterpark-attention12-name: 'waterpark-attention12';
$waterpark-attention12-x: 390px;
$waterpark-attention12-y: 260px;
$waterpark-attention12-offset-x: -390px;
$waterpark-attention12-offset-y: -260px;
$waterpark-attention12-width: 56px;
$waterpark-attention12-height: 55px;
$waterpark-attention12-total-width: 497px;
$waterpark-attention12-total-height: 445px;
$waterpark-attention12-image: '@@img/sprite/waterpark.png';
$waterpark-attention12: (390px, 260px, -390px, -260px, 56px, 55px, 497px, 445px, '@@img/sprite/waterpark.png', 'waterpark-attention12', );
$waterpark-attention13-name: 'waterpark-attention13';
$waterpark-attention13-x: 0px;
$waterpark-attention13-y: 390px;
$waterpark-attention13-offset-x: 0px;
$waterpark-attention13-offset-y: -390px;
$waterpark-attention13-width: 55px;
$waterpark-attention13-height: 55px;
$waterpark-attention13-total-width: 497px;
$waterpark-attention13-total-height: 445px;
$waterpark-attention13-image: '@@img/sprite/waterpark.png';
$waterpark-attention13: (0px, 390px, 0px, -390px, 55px, 55px, 497px, 445px, '@@img/sprite/waterpark.png', 'waterpark-attention13', );
$waterpark-attention14-name: 'waterpark-attention14';
$waterpark-attention14-x: 390px;
$waterpark-attention14-y: 195px;
$waterpark-attention14-offset-x: -390px;
$waterpark-attention14-offset-y: -195px;
$waterpark-attention14-width: 56px;
$waterpark-attention14-height: 55px;
$waterpark-attention14-total-width: 497px;
$waterpark-attention14-total-height: 445px;
$waterpark-attention14-image: '@@img/sprite/waterpark.png';
$waterpark-attention14: (390px, 195px, -390px, -195px, 56px, 55px, 497px, 445px, '@@img/sprite/waterpark.png', 'waterpark-attention14', );
$waterpark-attention2-name: 'waterpark-attention2';
$waterpark-attention2-x: 390px;
$waterpark-attention2-y: 130px;
$waterpark-attention2-offset-x: -390px;
$waterpark-attention2-offset-y: -130px;
$waterpark-attention2-width: 58px;
$waterpark-attention2-height: 55px;
$waterpark-attention2-total-width: 497px;
$waterpark-attention2-total-height: 445px;
$waterpark-attention2-image: '@@img/sprite/waterpark.png';
$waterpark-attention2: (390px, 130px, -390px, -130px, 58px, 55px, 497px, 445px, '@@img/sprite/waterpark.png', 'waterpark-attention2', );
$waterpark-attention3-name: 'waterpark-attention3';
$waterpark-attention3-x: 390px;
$waterpark-attention3-y: 0px;
$waterpark-attention3-offset-x: -390px;
$waterpark-attention3-offset-y: 0px;
$waterpark-attention3-width: 58px;
$waterpark-attention3-height: 55px;
$waterpark-attention3-total-width: 497px;
$waterpark-attention3-total-height: 445px;
$waterpark-attention3-image: '@@img/sprite/waterpark.png';
$waterpark-attention3: (390px, 0px, -390px, 0px, 58px, 55px, 497px, 445px, '@@img/sprite/waterpark.png', 'waterpark-attention3', );
$waterpark-attention4-name: 'waterpark-attention4';
$waterpark-attention4-x: 260px;
$waterpark-attention4-y: 325px;
$waterpark-attention4-offset-x: -260px;
$waterpark-attention4-offset-y: -325px;
$waterpark-attention4-width: 62px;
$waterpark-attention4-height: 55px;
$waterpark-attention4-total-width: 497px;
$waterpark-attention4-total-height: 445px;
$waterpark-attention4-image: '@@img/sprite/waterpark.png';
$waterpark-attention4: (260px, 325px, -260px, -325px, 62px, 55px, 497px, 445px, '@@img/sprite/waterpark.png', 'waterpark-attention4', );
$waterpark-attention5-name: 'waterpark-attention5';
$waterpark-attention5-x: 260px;
$waterpark-attention5-y: 260px;
$waterpark-attention5-offset-x: -260px;
$waterpark-attention5-offset-y: -260px;
$waterpark-attention5-width: 62px;
$waterpark-attention5-height: 55px;
$waterpark-attention5-total-width: 497px;
$waterpark-attention5-total-height: 445px;
$waterpark-attention5-image: '@@img/sprite/waterpark.png';
$waterpark-attention5: (260px, 260px, -260px, -260px, 62px, 55px, 497px, 445px, '@@img/sprite/waterpark.png', 'waterpark-attention5', );
$waterpark-attention6-name: 'waterpark-attention6';
$waterpark-attention6-x: 390px;
$waterpark-attention6-y: 65px;
$waterpark-attention6-offset-x: -390px;
$waterpark-attention6-offset-y: -65px;
$waterpark-attention6-width: 58px;
$waterpark-attention6-height: 55px;
$waterpark-attention6-total-width: 497px;
$waterpark-attention6-total-height: 445px;
$waterpark-attention6-image: '@@img/sprite/waterpark.png';
$waterpark-attention6: (390px, 65px, -390px, -65px, 58px, 55px, 497px, 445px, '@@img/sprite/waterpark.png', 'waterpark-attention6', );
$waterpark-attention7-name: 'waterpark-attention7';
$waterpark-attention7-x: 458px;
$waterpark-attention7-y: 0px;
$waterpark-attention7-offset-x: -458px;
$waterpark-attention7-offset-y: 0px;
$waterpark-attention7-width: 39px;
$waterpark-attention7-height: 55px;
$waterpark-attention7-total-width: 497px;
$waterpark-attention7-total-height: 445px;
$waterpark-attention7-image: '@@img/sprite/waterpark.png';
$waterpark-attention7: (458px, 0px, -458px, 0px, 39px, 55px, 497px, 445px, '@@img/sprite/waterpark.png', 'waterpark-attention7', );
$waterpark-attention8-name: 'waterpark-attention8';
$waterpark-attention8-x: 332px;
$waterpark-attention8-y: 260px;
$waterpark-attention8-offset-x: -332px;
$waterpark-attention8-offset-y: -260px;
$waterpark-attention8-width: 44px;
$waterpark-attention8-height: 55px;
$waterpark-attention8-total-width: 497px;
$waterpark-attention8-total-height: 445px;
$waterpark-attention8-image: '@@img/sprite/waterpark.png';
$waterpark-attention8: (332px, 260px, -332px, -260px, 44px, 55px, 497px, 445px, '@@img/sprite/waterpark.png', 'waterpark-attention8', );
$waterpark-attention9-name: 'waterpark-attention9';
$waterpark-attention9-x: 332px;
$waterpark-attention9-y: 325px;
$waterpark-attention9-offset-x: -332px;
$waterpark-attention9-offset-y: -325px;
$waterpark-attention9-width: 42px;
$waterpark-attention9-height: 55px;
$waterpark-attention9-total-width: 497px;
$waterpark-attention9-total-height: 445px;
$waterpark-attention9-image: '@@img/sprite/waterpark.png';
$waterpark-attention9: (332px, 325px, -332px, -325px, 42px, 55px, 497px, 445px, '@@img/sprite/waterpark.png', 'waterpark-attention9', );
$waterpark-bad-name: 'waterpark-bad';
$waterpark-bad-x: 263px;
$waterpark-bad-y: 390px;
$waterpark-bad-offset-x: -263px;
$waterpark-bad-offset-y: -390px;
$waterpark-bad-width: 58px;
$waterpark-bad-height: 44px;
$waterpark-bad-total-width: 497px;
$waterpark-bad-total-height: 445px;
$waterpark-bad-image: '@@img/sprite/waterpark.png';
$waterpark-bad: (263px, 390px, -263px, -390px, 58px, 44px, 497px, 445px, '@@img/sprite/waterpark.png', 'waterpark-bad', );
$waterpark-good-name: 'waterpark-good';
$waterpark-good-x: 195px;
$waterpark-good-y: 390px;
$waterpark-good-offset-x: -195px;
$waterpark-good-offset-y: -390px;
$waterpark-good-width: 58px;
$waterpark-good-height: 44px;
$waterpark-good-total-width: 497px;
$waterpark-good-total-height: 445px;
$waterpark-good-image: '@@img/sprite/waterpark.png';
$waterpark-good: (195px, 390px, -195px, -390px, 58px, 44px, 497px, 445px, '@@img/sprite/waterpark.png', 'waterpark-good', );
$waterpark-guide-room1-name: 'waterpark-guide-room1';
$waterpark-guide-room1-x: 0px;
$waterpark-guide-room1-y: 260px;
$waterpark-guide-room1-offset-x: 0px;
$waterpark-guide-room1-offset-y: -260px;
$waterpark-guide-room1-width: 120px;
$waterpark-guide-room1-height: 120px;
$waterpark-guide-room1-total-width: 497px;
$waterpark-guide-room1-total-height: 445px;
$waterpark-guide-room1-image: '@@img/sprite/waterpark.png';
$waterpark-guide-room1: (0px, 260px, 0px, -260px, 120px, 120px, 497px, 445px, '@@img/sprite/waterpark.png', 'waterpark-guide-room1', );
$waterpark-guide-room2-name: 'waterpark-guide-room2';
$waterpark-guide-room2-x: 260px;
$waterpark-guide-room2-y: 130px;
$waterpark-guide-room2-offset-x: -260px;
$waterpark-guide-room2-offset-y: -130px;
$waterpark-guide-room2-width: 120px;
$waterpark-guide-room2-height: 120px;
$waterpark-guide-room2-total-width: 497px;
$waterpark-guide-room2-total-height: 445px;
$waterpark-guide-room2-image: '@@img/sprite/waterpark.png';
$waterpark-guide-room2: (260px, 130px, -260px, -130px, 120px, 120px, 497px, 445px, '@@img/sprite/waterpark.png', 'waterpark-guide-room2', );
$waterpark-guide-room3-name: 'waterpark-guide-room3';
$waterpark-guide-room3-x: 260px;
$waterpark-guide-room3-y: 0px;
$waterpark-guide-room3-offset-x: -260px;
$waterpark-guide-room3-offset-y: 0px;
$waterpark-guide-room3-width: 120px;
$waterpark-guide-room3-height: 120px;
$waterpark-guide-room3-total-width: 497px;
$waterpark-guide-room3-total-height: 445px;
$waterpark-guide-room3-image: '@@img/sprite/waterpark.png';
$waterpark-guide-room3: (260px, 0px, -260px, 0px, 120px, 120px, 497px, 445px, '@@img/sprite/waterpark.png', 'waterpark-guide-room3', );
$waterpark-guide-room4-name: 'waterpark-guide-room4';
$waterpark-guide-room4-x: 130px;
$waterpark-guide-room4-y: 130px;
$waterpark-guide-room4-offset-x: -130px;
$waterpark-guide-room4-offset-y: -130px;
$waterpark-guide-room4-width: 120px;
$waterpark-guide-room4-height: 120px;
$waterpark-guide-room4-total-width: 497px;
$waterpark-guide-room4-total-height: 445px;
$waterpark-guide-room4-image: '@@img/sprite/waterpark.png';
$waterpark-guide-room4: (130px, 130px, -130px, -130px, 120px, 120px, 497px, 445px, '@@img/sprite/waterpark.png', 'waterpark-guide-room4', );
$waterpark-guide-season1-name: 'waterpark-guide-season1';
$waterpark-guide-season1-x: 0px;
$waterpark-guide-season1-y: 130px;
$waterpark-guide-season1-offset-x: 0px;
$waterpark-guide-season1-offset-y: -130px;
$waterpark-guide-season1-width: 120px;
$waterpark-guide-season1-height: 120px;
$waterpark-guide-season1-total-width: 497px;
$waterpark-guide-season1-total-height: 445px;
$waterpark-guide-season1-image: '@@img/sprite/waterpark.png';
$waterpark-guide-season1: (0px, 130px, 0px, -130px, 120px, 120px, 497px, 445px, '@@img/sprite/waterpark.png', 'waterpark-guide-season1', );
$waterpark-guide-season2-name: 'waterpark-guide-season2';
$waterpark-guide-season2-x: 130px;
$waterpark-guide-season2-y: 0px;
$waterpark-guide-season2-offset-x: -130px;
$waterpark-guide-season2-offset-y: 0px;
$waterpark-guide-season2-width: 120px;
$waterpark-guide-season2-height: 120px;
$waterpark-guide-season2-total-width: 497px;
$waterpark-guide-season2-total-height: 445px;
$waterpark-guide-season2-image: '@@img/sprite/waterpark.png';
$waterpark-guide-season2: (130px, 0px, -130px, 0px, 120px, 120px, 497px, 445px, '@@img/sprite/waterpark.png', 'waterpark-guide-season2', );
$waterpark-guide-season3-name: 'waterpark-guide-season3';
$waterpark-guide-season3-x: 130px;
$waterpark-guide-season3-y: 260px;
$waterpark-guide-season3-offset-x: -130px;
$waterpark-guide-season3-offset-y: -260px;
$waterpark-guide-season3-width: 120px;
$waterpark-guide-season3-height: 120px;
$waterpark-guide-season3-total-width: 497px;
$waterpark-guide-season3-total-height: 445px;
$waterpark-guide-season3-image: '@@img/sprite/waterpark.png';
$waterpark-guide-season3: (130px, 260px, -130px, -260px, 120px, 120px, 497px, 445px, '@@img/sprite/waterpark.png', 'waterpark-guide-season3', );
$waterpark-guide-season4-name: 'waterpark-guide-season4';
$waterpark-guide-season4-x: 0px;
$waterpark-guide-season4-y: 0px;
$waterpark-guide-season4-offset-x: 0px;
$waterpark-guide-season4-offset-y: 0px;
$waterpark-guide-season4-width: 120px;
$waterpark-guide-season4-height: 120px;
$waterpark-guide-season4-total-width: 497px;
$waterpark-guide-season4-total-height: 445px;
$waterpark-guide-season4-image: '@@img/sprite/waterpark.png';
$waterpark-guide-season4: (0px, 0px, 0px, 0px, 120px, 120px, 497px, 445px, '@@img/sprite/waterpark.png', 'waterpark-guide-season4', );
$waterpark-width: 497px;
$waterpark-height: 445px;
$waterpark-image: '@@img/sprite/waterpark.png';
$waterpark-sprites: ($waterpark-arrow-bottom, $waterpark-arrow-right, $waterpark-attention1, $waterpark-attention10, $waterpark-attention11, $waterpark-attention12, $waterpark-attention13, $waterpark-attention14, $waterpark-attention2, $waterpark-attention3, $waterpark-attention4, $waterpark-attention5, $waterpark-attention6, $waterpark-attention7, $waterpark-attention8, $waterpark-attention9, $waterpark-bad, $waterpark-good, $waterpark-guide-room1, $waterpark-guide-room2, $waterpark-guide-room3, $waterpark-guide-room4, $waterpark-guide-season1, $waterpark-guide-season2, $waterpark-guide-season3, $waterpark-guide-season4, );
$waterpark: (497px, 445px, '@@img/sprite/waterpark.png', $waterpark-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
