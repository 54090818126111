//
// 본문 메인 타이틀
// --------------------------------------------------

/* 본문 메인 타이틀 */
.content-main-title {
    text-align: center;
    font-size: 42px;
    letter-spacing: -.05em;
    color: #000;
    line-height: 1;
    margin-bottom: 40px;
}