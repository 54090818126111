//
// 게시판 상세
// --------------------------------------------------

/* 게시판 상세 */
.board-detail {
	.detail-header {
		position: relative;
		border-top: 1px solid #232323;
		border-bottom: 1px solid #ddd;
		padding: 14px 10px 13px 20px;
		letter-spacing: -.05em;

		.detail-tit {
			display: table;
			color: #232323;
			font-size: 18px;
			font-weight: 500;

			.board-flag-cate { margin-right: 10px; }
		}

		.tit-text {
			display: table-cell;
			padding-right: 150px;
			word-break: break-word;
		}

		.detail-info {
			font-size: 16px;
			position: absolute;
			right: 10px;
			top: 15px;

			dl {
				display: inline-block;

				dt {
					display: inline-block;
					margin-right: 10px;
					font-weight: 500;
				}
				dd {
					display: inline-block;
					font-weight: 300;
				}
				
				&:not(:first-child) {
					&:before {
						content: '';
						display: inline-block;
						width: 1px;
						height: 20px;
						margin: 0 20px;
						background: #ddd;
						vertical-align: -3px;
					}
				}
			}
		}

	}

	.detail-header-table {
		th:not(.td) { color: #232323; }
		td { padding: 5px 35px 4px; }
	}

	.detail-content {
		padding: 40px 20px;
		border-bottom: 1px solid #ddd;
		font-size: 16px;
		min-height: 200px;
		word-wrap: break-word;

		&.no-margin {
			padding-left: 0;
			padding-right: 0;
		}

		.center {
			text-align: center;
		}

		img {
			max-width: 100%;
		}
	}
}