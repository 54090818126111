//
// 사진 갤러리
// --------------------------------------------------

/* 사진 갤러리 */
.common-photo-slider {
	height: 100%;
	position: relative;

	&[data-len='1'] {
		.bx-pager-item { width: 0; overflow: hidden; }
		.bx-pager-item + .other-control { margin-left: 0; }
	}

	.bx-wrapper {
		width: 100%;
		height: 100%;
	}

	.bx-viewport {
		width: 100%;
		height: 100%;
		background: #999;
	}

	.slider-list {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	.slider-item {
		width: 100%;
		height: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50%;
		position: absolute;
		top: 0;
		left: 0;
	}

	.bx-controls-direction {
		width: 100%;
		max-width: $content-width;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 100;
		transform: translate(-50%,0);
	}

	.bx-prev {
		display: block;
		width: 44px;
		height: 67px;
		margin-top: -34px;
		top: 0;
		left: 15px;
		@include afterSpriteIR($ico-photo-prev,44px,67px,absolute);
	}

	.bx-next {
		display: block;
		width: 44px;
		height: 67px;
		margin-top: -34px;
		top: 0;
		right: 15px;
		@include afterSpriteIR($ico-photo-next,44px,67px,absolute);
	}

	.bx-pager {
		box-sizing: border-box;
		width: 100%;
		height: 38px;
		display: inline-block;
		vertical-align: top;
		text-align: center;
		font-size: 0;
		white-space: nowrap;
		position: absolute;
		top: calc(100% - 38px - 25px);
		left: 0;
		z-index: 100;
	}

	.bx-pager-item {
		display: inline-block;
		vertical-align: top;
		padding-top: 14px;

		&:not(:first-child) { margin-left: 10px; }

		& + .other-control { margin-left: 20px; }
	}

	.bx-pager-link {
		display: block;
		width: 10px;
		height: 10px;
		background: #fff;
		border-radius: 5px;

		&.active { width: 30px; }
	}

	.other-control {
		display: inline-block;
		vertical-align: top;

		button + button { margin-left: 10px; }
	}

	.vr-view,
	.video-view {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 101;
	}
}