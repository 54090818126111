//
// 기간조회 form
// --------------------------------------------------

/* 기간조회 form */
.search-period-form {
	margin-bottom: 50px;
	padding: 22px 0;
	background-color: #f5f5f5;
	text-align: center;

	.btn-area {
		display: inline-block;
		margin-left: 20px;

		.btn { 
			min-width: 80px;
			padding: 0 20px;
		}

		.btn:not(:last-child) { margin-right: 2px; }
		.primary { width: 120px; }
	}
}