/* stylelint-disable */

/* Basic Style START (dohoons, 200803, 200908, 201011, 201106, 201305, 201607, 201802) */
html, body { height:100%; -webkit-text-size-adjust:none; font-family:dotum,"돋움",Arial,Sans-serif; font-size:12px; }
body, input, select, button, textarea, h1, h2, h3, h4, h5, h6, table { line-height:1.5; font:inherit; color:inherit; }
html, body, div, form, input, select, button, textarea, legend, fieldset, h1, h2, h3, h4, h5, h6, ul, ol, dl, li, dt, dd, blockquote, address, p, th, td, caption { margin:0; padding:0; }
ol, ul, li { list-style:none; }
img, fieldset { vertical-align:middle; border:0 none; }
input, select, textarea { vertical-align:middle; resize:none; }
input[type=text], input[type=password], input[type=submit], input[type=search], input[type=email], input[type=tel], textarea { -webkit-appearance:none; }
button { border:0 none; background:transparent; cursor:pointer; }
hr { display:none; }
legend, .hide { position:absolute; left:-9999px; }
table caption { width:0; height:0; visibility:hidden; text-indent:-9999px; font-size:0; line-height:0; }
table { border-collapse:collapse; border-spacing:0; }
header, footer, section, article, aside, nav, hgroup, details, menu, figure, figcaption { display:block; }

a { color:inherit; text-decoration:none; }
a:active, a:hover, a:focus { text-decoration:underline; }
/* Basic Style END */

button::-moz-focus-inner {
  border: 0;
}