//
// 인풋
// --------------------------------------------------

/* 공통 인풋 */
input.txt {
	$height: 40px;
	$padding-lr: 15px;

	box-sizing: border-box;
	height: $height;
	line-height: $height;
	padding: 0 $padding-lr;
	vertical-align: middle;
	font-weight: 300;
	font-size: 16px;
	color: #4d4d4d;
	background: #fff;
	border: 1px solid #ddd;
	border-radius: 0;
	
	&:hover,
	&:focus {
		border-color: #232323;
		outline: 0;
	}

	&[readonly] {
		background-color: #f5f5f5;
		border-color: #ddd;
	}

	&[disabled] {
		background-color: #f5f5f5;
		border-color: #ddd;
	}

	// 100% 사이즈
	&.block {
		width: 100%;
	}

	// 금액입력 같은 경우 우측 정렬로 사용가능
	&.right {
		text-align: right;
	}

	// 사이즈
	// medium
	&.medium {
		$height: 46px;
		$padding-lr: 15px;
		
		height: $height;
		line-height: $height;
		padding: 0 $padding-lr;
	}
}

/* 텍스트 인풋 - textaera type */
textarea.txt {
	$padding-tb: 6px;
	$padding-lr: 15px;

	box-sizing: border-box;
	height: auto;
	padding: $padding-tb $padding-lr;
	line-height: 1.5;
	font-weight: 300;
	font-size: 16px;
	color: #4d4d4d;
	resize: none;
	border: 1px solid #ddd;
	border-radius: 0;

	&:hover,
	&:focus {
		border-color: #232323;
		outline: 0;
	}

	&[readonly] {
		background-color: #f5f5f5;
		border-color: #ddd;
	}

	&[disabled] {
		background-color: #f5f5f5;
		border-color: #ddd;
	}

	// 100% 사이즈
	&.block {
		width: 100%;
	}

	// 사이즈
	// medium
	&.medium {
		$padding-tb: 6px;
		$padding-lr: 15px;
		
		padding: $padding-tb $padding-lr;
	}
}