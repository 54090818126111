//
// 폼 레이아웃
// --------------------------------------------------

/* 폼 레이아웃 */
.layout-form {
	$rowHeight: 40px;

	@include clearfix;

	.form-row {
		display: table;
		width: 100%;
		table-layout: fixed;

		&:not(:last-child) { padding-bottom: 20px; }
	}

	.form-head {
		box-sizing: border-box;
		display: table-cell;
		width: 173px;
		height: $rowHeight;
		padding: 6px 0 0;
		font-weight: 500;
		font-size: 18px;
		color: #232323;
		vertical-align: top;
		letter-spacing: -.05em;
	}

	.form-cell {
		box-sizing: border-box;
		display: table-cell;
		vertical-align: middle;
	}

	.form-desc {
		margin-top: 14px;
		font-weight: 300;
		font-size: 15px;
		color: #666;
	}

	.form-desc + .form-desc { margin-top: 5px; }

	// 중간 사이즈
	&.medium {
		$rowHeight: 46px;

		.form-head {
			height: $rowHeight;
			padding: 8px 0 0;
		}
	}
}