//
// VR 재생영역
// --------------------------------------------------

/* VR 재생영역 */
.vr-view {
	width: 850px;
	height: 400px;
	background: #000;
	position: relative;
	z-index: 101;

	.btn-vr-close {
		display: block;
		width: 64px;
		height: 64px;
		top: 0;
		right: 0;
		@include afterSpriteIR($room-vr-close,64px,64px,absolute);
	}
}