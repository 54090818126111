//
// 프로젝트 공통 스타일
// --------------------------------------------------

/* Noto Sans KR */
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 300;
    src:
		url('../../../font/notosanskr-v2/NotoSansKR-Light.woff2') format('woff2'),
		url('../../../font/notosanskr-v2/NotoSansKR-Light.woff') format('woff'),
		url('../../../font/notosanskr-v2/NotoSansKR-Light.otf') format('opentype');
}
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    src:
		url('../../../font/notosanskr-v2/NotoSansKR-Regular.woff2') format('woff2'),
		url('../../../font/notosanskr-v2/NotoSansKR-Regular.woff') format('woff'),
		url('../../../font/notosanskr-v2/NotoSansKR-Regular.otf') format('opentype');
}
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 500;
    src:
		url('../../../font/notosanskr-v2/NotoSansKR-Medium.woff2') format('woff2'),
		url('../../../font/notosanskr-v2/NotoSansKR-Medium.woff') format('woff'),
		url('../../../font/notosanskr-v2/NotoSansKR-Medium.otf') format('opentype');
}
@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    src:
		url('../../../font/notosanskr-v2/NotoSansKR-Bold.woff2') format('woff2'),
		url('../../../font/notosanskr-v2/NotoSansKR-Bold.woff') format('woff'),
		url('../../../font/notosanskr-v2/NotoSansKR-Bold.otf') format('opentype');
}

// @import url('https://fonts.googleapis.com/css?family=Noto+Sans+KR:300,400,500,700&subset=korean');


html,
body { 
	font-family: 'Noto Sans KR', sans-serif;
	font-size: 14px;
    color: $site-font-color;
}

strong {
    font-weight: 500;
}