.info-box-list {
	border-top: 1px solid #222;

	.item {
		border-bottom: 1px solid #d9d8d6;
		display: table;
		width: 100%;
		table-layout: fixed;
		padding: 28px 0;
		position: relative;

		@include clearfix;
	}

	.tit {
		box-sizing: border-box;
		display: table-cell;
		width: 275px;
		vertical-align: middle;
		font-weight: 500;
		font-size: 20px;
		color: #232323;
		text-align: center;
		border-right: 1px solid #ddd;
	}

	.thumb {
		display: table-cell;
		width: 350px;
		vertical-align: middle;

		img { width: 100%; }
	}

	.info-area {
		box-sizing: border-box;
		display: table-cell;
		width: 575px;
		padding: 0 30px 0 65px;
		vertical-align: middle;

		.info-txt {
			margin: 10px 0;
			font-weight: 300;
			font-size: 16px;

			&:first-child { margin-top: 0; }
			& + .info-data { margin-top: 20px; }
		}

		.info-data {
			dl {
				font-size: 15px;
				color: #666;
				@include clearfix;

				&:not(:first-child) { margin-top: 10px; }
			}

			dt {
				float: left;
				width: 90px;
				font-weight: 500;
			}
			dd {
				float: right;
				width: calc(100% - 90px);
			}
		}
	}
}