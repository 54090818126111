//
// 공통 상단정보
// --------------------------------------------------

/* 공통 상단정보 */
.common-top-info {
	box-sizing: border-box;
	width: $content-width;
	height: 460px;
	margin: 0 auto;
	padding-right: 440px;
	background: #f5f5f5;
	position: relative;
	overflow: hidden;

	.info {
		box-sizing: border-box;
		width: 440px;
		height: 100%;
		padding: 0 0 0 40px;
		font-size: 0;
		white-space: nowrap;
		position: absolute;
		top: 0;
		right: 0;

		&:after {
			content: '';
			display: inline-block;
			width: 0;
			height: 100%;
			vertical-align: middle;
		}
	}

	.info-wrap {
		display: inline-block;
		vertical-align: middle;
		white-space: normal;
	}

	.info-copy {
		line-height: 1.3;
		font-weight: 500;
		font-size: 24px;
		color: #232323;
	}

	.info-copy2 {
		margin-top: 5px;
		line-height: 1.3;
		font-weight: 300;
		font-size: 14px;
		letter-spacing: 0;
	}

	.info-desc {
		padding-top: 36px;
		line-height: 26px;
		font-weight: 300;
		font-size: 16px;
		color: #666;
		position: relative;

		&:before {
			content: '';
			display: block;
			width: 28px;
			height: 1px;
			background: #232323;
			position: absolute;
			top: 18px;
			left: 0;
		}
	}

	.photo-slider {
		width: 760px;
		height: 460px;
		position: relative;
	}
}