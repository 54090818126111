//
// 상품 목록
// --------------------------------------------------

/* 상품 목록 - 텍스트형 */
.product-text-list {
	border-top: 1px solid #ddd;

	> li {
		box-sizing: border-box;
		display: table;
		width: 100%;
		height: 80px;
		padding: 0 20px;
		table-layout: fixed;
		border-bottom: 1px solid #ddd;

		&:not(.line) + .line {
			margin-top: -1px;
			border-top: 1px solid #232323;
		}
	}

	.title {
		display: table-cell;
		padding: 10px 0;
		font-weight: 500;
		font-size: 18px;
		color: #232323;
		vertical-align: middle;
	}

	.info {
		display: table-cell;
		width: 400px;
		padding-left: 50px;
		text-align: right;
		vertical-align: middle;
	}

	.list-opt {
		width: 110px;
		margin-right: 7px;
	}

	.btn-list-action {
		width: 100px;
		padding: 0;
	}

	.price {
		margin-right: 15px;
		vertical-align: middle;
		font-size: 22px;

		.num {
			font-weight: 700;
			color: #d14e40;

			&.disabled { color: #999; }
		}
	}
}