//
// 팝업 스타일
// --------------------------------------------------

/* 바디 스크롤 고정 */
body.scroll-fixed {
	overflow: hidden;
}

/* 기본 공통 팝업 - 레이어팝업 겸용 */
.common-pop {
	box-sizing: border-box;
	display: inline-block;
	position: relative;
	z-index: 1000;

	// 레이어팝업일때
	&.is-layer {
		display: block;
		width: 100% !important;
		height: 100% !important;
		text-align: center;
		font-size: 0;
		white-space: nowrap;
		background: rgba(0,0,0,.4);
		position: fixed;
		top: 0;
		left: 0;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		
		// 상대적으로 센터 정렬 위한 요소
		&.is-layer:after {
			content: '';
			display: inline-block;
			width: 0;
			height: 100%;
			vertical-align: middle;
		}
	}
}

.common-pop-main-msg {
	margin-bottom: 18px;
	line-height: 1.3;
	text-align: center;
	font-weight: 300;
	font-size: 22px;
	color: #232323;
	letter-spacing: -.05em;
}

.common-pop-foot-desc {
	margin-top: 13px;
	font-weight: 300;
	padding-left: 18px;
	text-indent: -18px;
	font-size: 15px;
}