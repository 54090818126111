/* 카테고리 플래그 */
.board-flag-cate {
	box-sizing: border-box;
	display: inline-block;
	min-width: 67px;
	line-height: 26px;
	padding: 0 18px;
	margin-right: 10px;
	vertical-align: middle;
	text-align: center;
	font-size: 15px;
	font-weight: 400;
	background: #fff;
	border: 2px solid #ededed;
	border-radius: 37px;

	&.type1 { color: #31a0e0; }
	&.type2 { color: #d8671a; }
}

.board-flag-cate2 {
	box-sizing: border-box;
	display: inline-block;
	min-width: 67px;
	height: 30px;
	margin-right: 15px;
	padding: 0 14px;
	background: #232323;
	color: #fff;
	font-size: 15px;
	font-weight: 300;
	text-align: center;
	vertical-align: middle;
	line-height: 30px;

	&.type1 { background: #069; }
	&.type2 { background: #d8671a; }
}

.board-flag-cate3 {
	box-sizing: border-box;
	display: inline-block;
	margin-right: 15px;
	padding-right: 15px;
	color: #b0895c;
	font-size: 16px;
	font-weight: 500;
	white-space: nowrap;
	vertical-align: middle;
	position: relative;
	top: -3px;

	&:after {
		content: '';
		display: block;
		width: 1px;
		height: 20px;
		background: #ddd;
		position: absolute;
		top: calc(50% - 10px);
		right: 0;
	}
}