//
// 헤더 스타일
// --------------------------------------------------

/* 공통 헤더 */
.common-header {

	.util-wrap {
		width: 100%;
		height: 95px;
		position: absolute;
		top: 0;
		left: 0;
	}

	.group-1 {
		position: absolute;
		top: 19px;
		left: 11px;

		@include clearfix;
	}

	.btn-all-menu {
		box-sizing: border-box;
		float: left;
		display: block;
		
		@include afterSpriteIR($ico-all-menu,57px,57px);
	}

	.nav-family {
		float: left;
		font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
		padding: 20px 0 0 1px;

		li {
			float: left;
			font-size: 14px;
			color: rgba(255,255,255,.7);
			letter-spacing: .025em;
			position: relative;

			&:not(:first-child) {
				margin-left: 20px;

				&:after {
					content: '';
					display: block;
					width: 2px;
					height: 2px;
					background: rgba(255,255,255,.7);
					position: absolute;
					top: 8px;
					left: -11px;
				}
			}

			&:hover, &:focus { color: rgba(255,255,255,.9); }

			&.selected {
				color: rgba(255,255,255,.9);

				&:before {
					content: '';
					display: block;
					width: 100%;
					height: 1px;
					background: rgba(255,255,255,.9);
					position: absolute;
					bottom: 0;
					left: 0;
				}
			}
		}
			
		a { text-decoration: none; }
	}

	.group-2 {
		position: absolute;
		top: 38px;
		right: 30px;

		@include clearfix;
	}

	.nav-util {
		float: left;
		padding: 0 18px 0 0;

		li {
			float: left;
			font-size: 12px;
			color: rgba(255,255,255,.7);
			letter-spacing: 0;
			position: relative;

			&:not(:first-child) {
				margin-left: 20px;

				&:after {
					content: '';
					display: block;
					width: 2px;
					height: 2px;
					background: rgba(255,255,255,.7);
					position: absolute;
					top: 8px;
					left: -11px;
				}
			}

			&:hover, &:focus { color: rgba(255,255,255,1); }
		}
			
		a { text-decoration: none; }
	}

	.user-info {
		font-weight: 300;
		font-size: 13px;
		color: rgba(255,255,255,.7);
		position: absolute;
		top: 26px;
		right: 0;

		.name { color: #fff; }
	}

	.select-lang {
		float: left;
		width: 54px;
		position: relative;

		.current {
			box-sizing: border-box;
			display: block;
			height: 19px;
			line-height: 17px;
			padding-left: 7px;
			font-size: 12px;
			color: rgba(255,255,255,.7);
			text-decoration: none;
			border: 1px solid rgba(255,255,255,.7);
			position: relative;

			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 7px;
				right: 7px;
				opacity: .7;

				@include sprite($ico-arw-lang);
			}
		}

		.lang-list {
			display: none;
			box-sizing: border-box;
			width: 100%;
			line-height: 17px;
			padding-left: 7px;
			font-size: 12px;
			color: rgba(255,255,255,.7);
			background: #302d2d;
			border: 1px solid rgba(255,255,255,.7);
			position: absolute;
			top: 0;
			left: 0;

			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 7px;
				right: 7px;
				opacity: .7;

				@include sprite($ico-arw-lang);
			}

			a {
				text-decoration: none;
				&:hover, &:focus { color: #fff; }
			}
		}
		
		&.is-open {
			.current { opacity: 0; }
			.lang-list { display: block; }
		}
	}

	/* 스크롤 상태 */
	&.is-scroll {

		.util-wrap {
			position: absolute;
			left: -9999px;
		}
	}
}