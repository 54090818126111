//
// 셀렉트
// --------------------------------------------------

/* 셀렉트박스 */
.select {
	$height: 40px;

	box-sizing: border-box;
	height: $height;
	padding: 0 0 0 15px;
	vertical-align: middle;
	// line-height: $height;
	font-weight: 300;
	font-size: 16px;
	color: #4d4d4d;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	&::-ms-expand { opacity: 0; }

	&:hover,
	&:focus {
		border-color: #232323;
		outline: 0;
	}

	&[disabled] {
		background-color: #f5f5f5;
		border-color: #ddd;
	}

	html.noIE & {
		padding-right: 25px;
	}

	html:not(.IE9) & {
		background-image: url('@@img/com/select-arw.png');
		background-repeat: no-repeat;
		background-position: 100% 50%;
	}

	// block
	&.block { width: 100%; }

	// 사이즈
	// medium
	&.medium {
		@extend .select;
		
		$height: 46px;

		height: $height;
	}
}