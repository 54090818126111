//
// 섹션 컨테이너
// --------------------------------------------------

/* 섹션 컨테이너 */
.section-container {
	min-width: $content-width;
	letter-spacing: -.025em;

	/* 섹션 상단 */
	> .section-head {
		background-color: #ddd;
		background-position: 50% 0;
		background-repeat: no-repeat;
		// background-attachment: fixed;
		position: relative;
		overflow: hidden;

		.title {
			line-height: 219px;
			padding: 150px 0 0;
			text-align: center;
			font-size: 40px;
			color: #fff;
			letter-spacing: -.04em;
		}
	}

	/* 섹션별 상단 이미지 (섹션 클래스 & .title) */
	&.section-join > .section-head { background-image: url('@@img/com/bg-section-common.jpg'); }
	&.section-login > .section-head { background-image: url('@@img/com/bg-section-common.jpg'); }
	&.section-reservation > .section-head { background-image: url('@@img/com/bg-section-reservation.jpg'); }
	&.section-mypage > .section-head { background-image: url('@@img/com/bg-section-common.jpg'); }
	&.section-customer > .section-head { background-image: url('@@img/com/bg-section-common.jpg'); }
	&.section-package > .section-head { background-image: url('@@img/com/bg-section-package.jpg'); }
	&.section-package > .section-head-pyeongchang { background-image: url('@@img/com/bg-section-package-pyeongchang.jpg'); }
	&.section-package > .section-head-jeju { background-image: url('@@img/com/bg-section-package-jeju.jpg'); }
	&.section-event > .section-head { background-image: url('@@img/com/bg-section-event.jpg'); }
	&.section-story > .section-head { background-image: url('@@img/com/bg-section-story.jpg'); }
	&.section-sellinfo > .section-head { background-image: url('@@img/com/bg-section-sellinfo.jpg'); }
	&.section-company > .section-head { background-image: url('@@img/com/bg-section-common.jpg'); }
	&.section-license > .section-head { background-image: url('@@img/com/bg-section-common.jpg'); }
	/* 평창 */
	&.site-pyeongchang.section-guide > .section-head { background-image: url('@@img/com/bg-section-pyeongchang-guide.jpg'); }
	&.site-pyeongchang.section-room > .section-head { background-image: url('@@img/com/bg-section-pyeongchang-room.jpg'); }
	&.site-pyeongchang.section-eating > .section-head { background-image: url('@@img/com/bg-section-pyeongchang-eating.jpg'); }
	&.site-pyeongchang.section-waterpark > .section-head { background-image: url('@@img/com/bg-section-pyeongchang-waterpark.jpg'); }
	&.site-pyeongchang.section-golf > .section-head { background-image: url('@@img/com/bg-section-pyeongchang-golf.jpg'); }
	&.site-pyeongchang.section-snowpark > .section-head { background-image: url('@@img/com/bg-section-pyeongchang-snowpark.jpg'); }
	&.site-pyeongchang.section-experience > .section-head { background-image: url('@@img/com/bg-section-pyeongchang-experience.jpg'); }
	&.site-pyeongchang.section-party > .section-head { background-image: url('@@img/com/bg-section-pyeongchang-party.jpg'); }
	/* 제주 */
	&.site-jeju.section-guide > .section-head { background-image: url('@@img/com/bg-section-jeju-guide.jpg'); }
	&.site-jeju.section-room > .section-head { background-image: url('@@img/com/bg-section-jeju-room.jpg'); }
	&.site-jeju.section-eating > .section-head { background-image: url('@@img/com/bg-section-jeju-eating.jpg'); }
	&.site-jeju.section-architecture > .section-head { background-image: url('@@img/com/bg-section-jeju-architecture.jpg'); }
	&.site-jeju.section-experience > .section-head { background-image: url('@@img/com/bg-section-jeju-experience.jpg'); }
	&.site-jeju.section-party > .section-head { background-image: url('@@img/com/bg-section-jeju-party.jpg'); }

	/* LNB 탭 내비게이션 */
	.nav-local {
		$height: 54px;

		width: $content-width;
		margin: -$height auto 0;
		text-align: center;
		font-size: 0;
		white-space: nowrap;
		position: relative;
		overflow: hidden;

		> li {
			display: inline-block;
			width: 20%;
			height: $height;
			line-height: $height;
			font-weight: 300;
			font-size: 18px;
			color: #fff;
			letter-spacing: -.05em;
			background: rgba(68,68,68,.9);

			&:not(:first-child) {
				width: calc(20% - 1px);
				border-left: 1px solid rgba(255,255,255,.3);
			}

			&.on {
				font-weight: 500;
				color: #232323;
				background: rgba(255,255,255,1);
			}
		}

		a {
			display: block;
			text-decoration: none;
		}

		/* 탭 개수별 width */
		&[data-len='2'] > li { width: 50%; }
		&[data-len='3'] > li { width: 33.33%; }
		&[data-len='3'] > li:first-child { width: 33.34%; }
		&[data-len='4'] > li { width: 25%; }
		&[data-len='5'] > li { width: 20%; }
		&[data-len='6'] > li { width: 16.66%; }
	}
}