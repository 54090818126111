//
// 공통 텍스트 스타일
// --------------------------------------------------

/* 가격 텍스트 표기 */
.txt-price {
	font-weight: 500;
	color: #232323;

	.num { color: #d14e40; }
}

/* 필수항목 * 표시 */
span.required {
	display: inline;
	font-size: 0;

	&:after {
		content: '*';
		display: inline;
		line-height: 1;
		font-weight: 500;
		font-size: 16px;
		color: $point;
		// position: relative;
		// top: 5px;
	}
}

/* 유효성 상태 텍스트 */
.state-msg {
	display: none;
	position: relative;

	&.is-valid {
		display: block;
		padding-left: 2px;
		margin-top: 14px;
		font-weight: 300;
		font-size: 15px;
		color: #b0895c;

		&:before {
			content: '';
			display: inline-block;
			margin-right: 10px;

			@include sprite($ico-state-valid);
		}
	}

	&.is-invalid {
		display: block;
		padding-left: 2px;
		margin-top: 14px;
		font-weight: 300;
		font-size: 15px;
		color: #b0895c;

		&:before {
			content: '';
			display: inline-block;
			margin-right: 10px;
			position: relative;
			top: 1px;

			@include sprite($ico-state-invalid);
		}
	}
}
span.state-msg {
	&.is-valid {
		display: inline-block;
		margin-top: 0;
		margin-left: 15px;
		padding-left: 0;
	}
	&.is-invalid {
		display: inline-block;
		margin-top: 0;
		margin-left: 15px;
		padding-left: 0;
	}
}

/* 가이드 링크 */
a.guide-link {
	box-sizing: border-box;
	display: inline-block;
	font-weight: 400;
	font-size: 15px;
	color: #232323;
	text-indent: 0;
	text-decoration: none;
	letter-spacing: -.05em;
	border-bottom: 1px solid #232323;

	&:after {
		content: '';
		display: inline-block;
		margin-left: 6px;
		position: relative;
		top: -2px;

		@include sprite($ico-guide-link);
	}
}

/* 공통 텍스트 문단  */
.common-txt {
	margin-bottom: 18px;
	padding: 0 5px;
	font-weight: 300;
	font-size: 16px;

	p, ul, ol, dl { margin: 5px 0; }

	.head-sub + & { margin-top: -5px; }
	& + & { margin-top: 0; }
}

/* 공통 하단 설명 */
.common-foot-desc {
	margin-top: 14px;
	font-weight: 300;
	font-size: 16px;
}

/* 블릿 리스트 */
.dot-bu-list {
	li {
		box-sizing: border-box;
		display: table;
		width: 100%;
		padding-left: 14px;
		font-weight: 300;
		font-size: 16px;
		letter-spacing: 0;
		position: relative;

		&:not(:first-child) { margin-top: 3px; }

		&:before {
			content: '';
			display: block;
			width: 3px;
			height: 3px;
			background: #b4b4b4;
			position: absolute;
			top: .7em;
			left: 2px;
		}
	}
}

/* 당구장 블릿 리스트 */
.ref-bu-list {
	padding-left: 19px;
	text-indent: -19px;
	font-weight: 300;
	font-size: 16px;
}