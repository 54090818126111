//
// 헤더 스타일
// --------------------------------------------------

/* 공통 헤더 */
.common-header {
	$zIndex: 500;

	box-sizing: border-box;
	width: 100%;
	min-width: $content-width;
	padding-top: 95px;
	color: #fff;
	background: rgba(48,45,45,.15);
	position: fixed;
	top: 0;
	left: 0;
	z-index: $zIndex + 1;
	transition: background .4s, padding .4s;

	.show-banner & { top: 100px; }
	
	& + .common-header-dim {
		content: '';
		display: block;
		width: 100%;
		height: calc(100% - 95px - 55px);
		background: rgba(48,45,45,0);
		position: fixed;
		bottom: 0;
		left: -9999px;
		z-index: $zIndex;
	}

	.site-logo a {
		top: -20px;
		transition: top .4s;
	}

	&:not(.is-scroll) {
		.site-logo {
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;

			a {
				$w: 124px;
				$h: 50px;

				width: $w;
				max-height: $h;
				padding-top: $h;
				margin-left: -($w / 2);

				display: block;
				height: 0;
				position: absolute;
				top: 25px;
				left: 50%;
				overflow: hidden;
			}
			&.is-hnr a { background: url('@@img/com/site-logo-hnr.png') no-repeat; }
			&.is-pyeongchang a { background: url('@@img/com/site-logo-pyeongchang.png') no-repeat; }
			&.is-jeju a { background: url('@@img/com/site-logo-jeju.png') no-repeat; }
		}
	}

	/* 오버 상태 */
	&.is-hover {
		background: rgba(48,45,45,1);
	}

	/* 스크롤 상태 */
	&.is-scroll {
		padding-top: 0;
		background: rgba(48,45,45,1);

		& + .common-header-dim {
			height: calc(100% - 55px);
		}

		.site-logo {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;

			a {
				$w: 88px;
				$h: 34px;

				width: $w;
				max-height: $h;
				padding-top: $h;
				display: block;
				height: 0;
				position: absolute;
				top: 10px;
				left: 22px;
				overflow: hidden;
			}
			&.is-hnr a { background: url('@@img/com/site-logo-hnr-scroll.png') no-repeat; }
			&.is-pyeongchang a { background: url('@@img/com/site-logo-pyeongchang-scroll.png') no-repeat; }
			&.is-jeju a { background: url('@@img/com/site-logo-jeju-scroll.png') no-repeat; }
		}
	}

	/* 메뉴 활성 상태 */
	&.is-active {
		background: rgba(48,45,45,1);

		& + .common-header-dim {
			background: rgba(48,45,45,.4);
			left: 0;
			transition: background .4s;
		}
	}
}