//
// 게시판 목록 상단검색2
// --------------------------------------------------

/* 게시판 목록 상단검색2 */
.list-search-form2 {
	width: 800px;
	height: 60px;
	margin: 0 auto 30px;

	@include clearfix;

	.form-wrap {
		box-sizing: border-box;
		display: block;
		position: relative;
		float: left;
		width: calc(100% - 60px);
		height: 60px;
		border: 1px solid #232323;
		border-right: 0;

		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 20px;
			left: 178px;
			width: 1px;
			height: 20px;
			background: #ddd;
		}
	}

	input.txt,
	select.select {
		display: block;
		float: left;
		height: 58px;
		line-height: 58px;
		border: none;
		font-size: 16px;
	}

	select.select {
		width: 176px;
		padding-left: 25px;
		font-weight: 500;
		text-align: left;
	}

	input.txt { 
		width: 562px;
		padding-left: 20px;
		font-weight: 300;
	}

	.btn-submit {
		float: right;
		width: 60px;
		height: 60px;
		background: #232323 url('@@img/com/ico-search.png') no-repeat 50%;

		span { position: relative; z-index: -1; }
	}
}