//
// 썸네일 정보 목록
// --------------------------------------------------

/* 썸네일 정보 목록 */
.thumb-info-list {
	font-size: 0;
	
	.item {
		display: inline-block;
		width: 585px;
		min-height: 300px;
		vertical-align: top;

		&:not(:nth-child(2n-1)) { margin-left: 30px; }
	 }

	.title {
		margin-bottom: 18px;
		padding-left: 5px;
		line-height: 1.2;
		font-weight: 500;
		font-size: 24px;
		color: #232323;
		letter-spacing: -.05em;
	}

	.con {
		padding-top: 180px;

		.info {
			box-sizing: border-box;
			width: 465px;
			margin-left: 60px;
			padding: 36px 20px 40px;
			background: #fff;
			font-size: 15px;
			font-weight: 300;
			text-align: center;
		}

		.contact {
			color: #232323;
			font-size: 16px;
		}
		
		.btn {
			width: 130px;
			margin-top: 16px;
		}

		/* 간격 */
		.desc + .contact { margin-top: 10px; }
		.desc + .btn { margin-top: 28px; }
	}

	.no-item {
		padding: 190px 0;
		border-top: 1px solid #232323;
		border-bottom: 1px solid #d4d4d4;
		font-size: 18px;
		font-weight: 300;
		text-align: center;
	}
}