//
// 상단 헤더 배너
// --------------------------------------------------

/* 상단 헤더 배너 */
.header-banner {
	width: 100%;
	min-width: $content-width;
	height: 100px;
	background-color: #232323;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 600;

	.wrapper > & { display: none; }
	.wrapper.show-banner > & { display: block; }

	.btn-close {
		display: block;
		position: absolute;
		top: 15px;
		right: 20px;

		@include afterSpriteIR($ico-header-banner-close,70px,70px,absolute);
	}

	.wrap {
		display: table;
		width: $content-width;
		margin: 0 auto;
		table-layout: fixed;
	}

	.txt-banner {
		box-sizing: border-box;
		display: table-cell;
		// width: 100%;
		height: 100px;
		text-align: center;
		text-decoration: none;
		vertical-align: middle;

		.main {
			display: block;
			font-weight: 500;
			font-size: 22px;
			color: rgba(0,0,0,.8);

			@include ellipsis;
		}

		.sub {
			display: block;
			margin-top: 2px;
			font-size: 14px;
			color: rgba(0,0,0,.5);

			@include ellipsis;
		}

		&.white {
			.main { color: #fff; }
			.sub { color: rgba(255,255,255,.6); }
		}
	}
	
}