//
// 환경변수 설정
// --------------------------------------------------

// 사이트 min-width
$site-min-width: 1200px;

// 본문 width
$content-width: 1200px;

// 기본 폰트 색상
$site-font-color: #666;

// 메인 컬러
$main-color: #232323;
$main-color2: #5f5f5f;

// 서브 컬러
$sub-color: #002d4d;
$sub-color2: #006097;
$sub-color3: #d8671a;
$sub-color4: #9b8877;

// 포인트 컬러
$point: #b0895c;
$point2: #006097;
$point3: #d8671a;