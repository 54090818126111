//
// VIDEO 재생영역
// --------------------------------------------------

/* VIDEO 재생영역 */
.video-view {
	width: 850px;
	height: 400px;
	background: #000;
	position: relative;
	z-index: 101;

	.btn-video-close {
		display: block;
		width: 64px;
		height: 64px;
		top: 0;
		right: 0;
		@include afterSpriteIR($room-vr-close,64px,64px,absolute);
	}
}