//
// 타이틀바 없는 박스 팝업
// --------------------------------------------------

/* 타이틀바 없는 박스 팝업 */
.blank-pop {

	/* 팝업 메인 */
	.common-pop-body {
		box-sizing: border-box;
		display: inline-block;
		width: 640px;
		padding: 60px 35px 35px;
		vertical-align: middle;
		text-align: left;
		font-size: 12px;
		white-space: normal;
		background: #fff;
		outline: none;
		position: relative;
		z-index: 1001;

		.is-layer & {
			box-shadow: 3px 3px 10px rgba(0,0,0,.1);
		}
	}

	/* 팝업 타이틀 */
	.common-pop-title {
		height: 50px;

		.title {
			line-height: 1.2;
			text-align: center;
			font-weight: 500;
			font-size: 28px;
			color: #232323;
			letter-spacing: -.05em;
		}

		.btn-pop-close {
			display: block;
			position: absolute;
			top: 11px;
			right: 13px;
			cursor: pointer;

			@include afterSpriteIR($ico-blank-pop-close, 50px, 50px, absolute);
		}
	}

	/* 팝업 내용 영역 */
	.common-pop-content {
		padding: 0;
		letter-spacing: -.05em;
	}

	/* 팝업 하단 버튼 영역 */
	.common-pop-btn-area {
		margin-top: 30px;
		text-align: center;

		.btn-action + .btn-action { margin-left: 5px; }
	}
}