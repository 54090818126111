//
// 빅배너
// --------------------------------------------------

/* 빅배너 */
.big-banner {
	margin-bottom: 60px;
	position: relative;

	.control {
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
	}

	.bx-pager {
		width: 100%;
		text-align: center;
		font-size: 0;
		white-space: nowrap;
		position: absolute;
		bottom: 40px;
		left: 0;

		.bx-pager-item {
			display: inline-block;
			vertical-align: top;

			&:not(:first-child) { margin-left: 10px; }
		}

		.bx-pager-link {
			display: block;
			width: 10px;
			height: 10px;
			background: #fff;
			border-radius: 5px;
			transition: all .4s;

			&.active { width: 30px; }
		}
	}

	/* 빅배너 슬라이더 */
	.big-banner-slider {
		a { display: block; }

		img {
			width: 1200px;
			height: 400px;
		}
	}
}