//
// 본문 상단 배너
// --------------------------------------------------

/* 본문 상단 배너 */
.contents-banner {
	width: 100%;
	min-width: $content-width;
	background-color: #fff;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	position: relative;
	z-index: 1;

	&.is-active {
		border-bottom: 1px solid #ddd;
		
		.btn-close { background-image: url('@@img/com/contents-banner-close.png'); bottom: -32px; }
		.wrap { display: table; }
	}

	.btn-close {
		$btnWidth: 90px;
		$btnPosition: 600px - $btnWidth;

		box-sizing: border-box;
		display: block;
		width: $btnWidth;
		height: 31px;
		line-height: 30px;
		padding-left: 24px;
		font-size: 14px;
		color: #232323;
		background: #fff url('@@img/com/contents-banner-open.png') no-repeat 21px 50%;
		border: 1px solid #ddd;
		border-top: 0;
		position: absolute;
		bottom: -31px;
		left: calc(50% + #{$btnPosition});
	}

	.wrap {
		display: none;
		width: $content-width;
		height: 140px;
		margin: 0 auto;
		table-layout: fixed;
	}

	.txt-banner {
		box-sizing: border-box;
		display: table-cell;
		// width: 100%;
		height: 140px;
		padding: 0 0 0 100px;
		text-align: center;
		text-decoration: none;
		vertical-align: middle;

		.main {
			display: block;
			font-weight: 500;
			font-size: 20px;
			color: rgba(0,0,0,.8);

			@include ellipsis;
		}

		.sub {
			display: block;
			margin-top: 2px;
			font-size: 14px;
			color: rgba(0,0,0,.5);

			@include ellipsis;
		}

		&.white {
			.main { color: #fff; }
			.sub { color: rgba(255,255,255,.6); }
		}
	}
	
}