//
// 공통 페이징
// --------------------------------------------------

/* 공통 페이징 */
.paging-common {
	height: 34px;
	margin-top: 50px;
	text-align: center;
	font-size: 0;
	position: relative;
	z-index: 1;

	a {
		box-sizing: border-box;
		display: inline-block;
		height: 34px;
		line-height: 34px;
		vertical-align: top;
		text-decoration: none;
	}

	.paging-num {
		display: inline-block;
		vertical-align: top;
		font-size: 0;

		a {
			padding: 0 12px;
			font-weight: 300;
			font-size: 15px;
			color: #333;
			background: #fff;
			border: 1px solid #fff;
			position: relative;

			&:not(:first-child) {
				margin-left: 3px;
			}
			
			&:hover,
			&:focus {
				text-decoration: underline;
			}

			&.on {
				color: #fff;
				background-color: #232323;
				border-color: #232323;
			}
		}
	}

	.page-btn {
		width: 34px;
		border: 1px solid #ddd;

		&:hover,
		&:focus {
			border: 1px solid #232323;
			position: relative;
			z-index: 1;
		}

		&:active { background-color: #fafafa; }

		&.first {
			margin-right: 10px;
			@include afterSpriteIR($ico-page-first, 32px, 32px, relative);
		}

		&.prev {
			margin-right: 20px;
			@include afterSpriteIR($ico-page-prev, 32px, 32px, relative);
		}

		&.next {
			margin-left: 20px;
			@include afterSpriteIR($ico-page-next, 32px, 32px, relative);
		}

		&.last {
			margin-left: 10px;
			@include afterSpriteIR($ico-page-last, 32px, 32px, relative);
		}
	}
}