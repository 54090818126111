//
// 게시판 등록
// --------------------------------------------------

/* 게시판 등록 */
.board-write {
	border-top: 1px solid #232323;
	border-bottom: 1px solid #ddd;

	.board-row {
		@include clearfix;

		.head {
			box-sizing: border-box;
			float: left;
			width: 210px;
			height: 60px;
			padding: 17px 25px;
			border-bottom: 1px solid #ddd;
			color: #666;
			font-size: 16px;
			font-weight: 500;
			text-align: left;
		}

		.cell {
			box-sizing: border-box;
			float: right;
			width: calc(100% - 210px);
			height: 60px;
			padding: 7px 0 6px;
			border-bottom: 1px solid #ddd;
		}
	}

	.editor-area {
		margin: 30px 0;
		height: 450px;
		background: #f5f5f5;
	}

}