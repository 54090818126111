//
// 텍스트 탭메뉴
// --------------------------------------------------

/* 텍스트 탭메뉴 */
.tab-text {
	padding: 0 12px;
	margin-bottom: 30px;

	@include clearfix;

	.tab {
		float: left;
		font-size: 16px;
		color: #999;
		position: relative;

		&:not(:first-child) {
			margin-left: 30px;

			&:before {
				content: '';
				display: block;
				width: 1px;
				height: 10px;
				background: #ddd;
				position: absolute;
				top: 8px;
				left: -15px;
			}
		}
		
		&.selected {
			a {
				font-weight: 500;
				color: #232323;
			}
		}
	}

	&.type1 .tab.selected a { color: #006097; }
	&.type2 .tab.selected a { color: #d8671a; }
}