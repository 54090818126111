//
// 푸터 스타일
// --------------------------------------------------

/* 공통 푸터 */
.common-footer {
	box-sizing: border-box;
	width: 100%;
	min-width: $content-width;
	height: 200px;
	background: #232323;
	position: absolute;
	bottom: 0;
	left: 0;

	&-wrap {
		box-sizing: border-box;
		max-width: $content-width;
		margin: 0 auto;
		padding: 41px 0 0 100px;
		position: relative;
		z-index: 1;
	}

	.logo {
		position: absolute;
		top: 41px;
		left: 0;
	}

	.foot-nav {
		font-size: 0;

		li {
			display: inline;
			font-size: 14px;
			color: #fff;
			position: relative;

			&:not(:first-child) {
				margin-left: 30px;

				&:after {
					content: '';
					display: block;
					width: 2px;
					height: 2px;
					background: rgba(255,255,255,.3);
					position: absolute;
					top: 10px;
					left: -16px;
				}
			}
		}
	}

	.info {
		margin-top: 20px;
		font-style: normal;
		font-weight: 300;
		font-size: 12px;
		color: rgba(255,255,255,.5);

		.row {
			display: block;
			&:not(:first-child) { margin-top: 3px; }
		}

		.cell {
			position: relative;

			&:not(:first-child) {
				margin-left: 21px;

				&:after {
					content: '';
					display: block;
					width: 1px;
					height: 11px;
					background: #4f4f4f;
					position: absolute;
					top: 4px;
					left: -12px;
				}
			}
		}

		a[href^='mailto:'] { letter-spacing: 0; }
	}

	.copy {
		margin-top: 3px;
		font-style: normal;
		font-weight: 300;
		font-size: 12px;
		color: rgba(255,255,255,.5);
	}

	.family-site {
		position: absolute;
		top: 37px;
		right: 0;

		.toggle {
			box-sizing: border-box;
			display: block;
			width: 140px;
			height: 30px;
			line-height: 28px;
			padding-left: 15px;
			text-align: left;
			font-size: 13px;
			color: #919191;
			background: none;
			border: 1px solid #919191;
			position: relative;
			z-index: 1;

			&:after {
				content: '';
				display: block;
				position: absolute;
				top: calc(50% - 2px);
				right: 13px;
				@include sprite($ico-family-site-off);
			}
		}

		.link-list {
			box-sizing: border-box;
			display: none;
			width: $content-width;
			background: #fff;
			border: 1px solid #232323;
			position: absolute;
			bottom: 29px;
			right: 0;

			@include clearfix;
		}

		.part {
			float: left;
			box-sizing: border-box;
			width: calc(100% / 6);
			padding: 36px 28px;

			&:not(:first-child) {
				border-left: 1px solid #eee;
			}
		}

		.cate-title {
			display: block;
			height: 47px;
			line-height: 1.3;
			font-weight: 500;
			font-size: 18px;
			color: #232323;
		}

		dd {
			display: block;
			line-height: 1.3;

			&:not(:last-child) {
				margin-bottom: 8px;
			}
		}

		a {
			&:hover,
			&:focus {
				color: $point;
			}
		}
		
		&.on {
			.toggle {
				color: #232323;
				background: #fff;
				border-color: #232323;
				border-top: 0;
				&:after { @include sprite($ico-family-site-on); }
			}
			.link-list { display: block; }
		}
	}

	.foot-util {
		position: absolute;
		top: 84px;
		right: 0;

		@include clearfix;
	}

	.link-nav {
		overflow: hidden;

		li {
			float: left;
			&:not(:last-child) { margin-right: 11px; }
		}
	}

	.btn-link {
		display: block;
		height: 28px;
		line-height: 28px;
		padding: 0 17px;
		font-size: 12px;
		color: #999;
		letter-spacing: .05em;
		text-decoration: none;
		border: 1px solid #999;
		border-radius: 15px;

		&:hover, &:focus { color: #bbb; border-color: #fff; }
	}

	.ico-link {
		display: block;
		width: 28px;
		height: 0;
		max-height: 28px;
		padding-top: 28px;
		text-decoration: none;
		border: 1px solid #999;
		border-radius: 15px;
		overflow: hidden;

		&:hover, &:focus { border-color: #fff; }

		&.facebook { background: url('@@img/com/footer-facebook.png') no-repeat; }
		&.blog { background: url('@@img/com/footer-blog.png') no-repeat; }
		&.instagram { background: url('@@img/com/footer-instagram.png') no-repeat; }
	}

	.weather-info {
		float: right;
		margin-top: 20px;

		@include clearfix;

		.ico {
			float: left;
			width: 48px;

			img {
				width: 38px;
				height: 38px;
			}
		}

		.txt {
			float: right;
			width: calc(100% - 48px);
			margin-top: 4px;
			line-height: 1.3;
			font-weight: 300;
			font-size: 12px;
			color: #fff;
		}
	}

	.btn-top {
		box-sizing: border-box;
		display: block;
		width: 58px;
		height: 58px;
		padding: 27px 0 0;
		text-align: center;
		font-weight: 500;
		font-size: 14px;
		color: #111;
		background: #fff url('@@img/com/btn-top.png') no-repeat;
		text-decoration: none;
		position: absolute;
		bottom: calc(100% + 20px);
		// left: calc(100% + 20px);
		right: 20px;
		overflow: hidden;
	}
}