//
// 페이지 탭메뉴
// --------------------------------------------------

/* 페이지 탭메뉴 */
.tab-page {
	margin-bottom: 20px;

	@include clearfix;

	/* 마진 없이 사용 */
	&.no-margin { margin-bottom: 0; }

	a {
		box-sizing: border-box;
		display: block;
		height: 80px;
		line-height: 76px;
		text-align: center;
		font-size: 18px;
		color: #999;
		text-decoration: none;
		border-top: 4px solid #ddd;
	}

	.tab {
		float: left;

		&.selected a {
			font-weight: 500;
			color: #b0895c;
			border-color: #b0895c;
		}
	}

	/* 탭 개수별 width */
	&[data-len='2'] .tab { width: calc(100% / 2); }
	&[data-len='3'] .tab { width: calc(100% / 3); }
	&[data-len='4'] .tab { width: calc(100% / 4); }
	&[data-len='5'] .tab { width: calc(100% / 5); }
	&[data-len='6'] .tab { width: calc(100% / 6); }
	&[data-len='7'] .tab { width: calc(100% / 7); }
	&[data-len='8'] .tab { width: calc(100% / 8); }
	&[data-len='9'] .tab { width: calc(100% / 9); }
	&[data-len='10'] .tab { width: calc(100% / 10); }

	/* 포인트 색상 타입 */
	&.type1 .tab.selected a { color: #006097; border-color: #006097; }
	&.type2 .tab.selected a { color: #d8671a; border-color: #d8671a; }
}