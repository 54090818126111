//
// 데이터 레이아웃 테이블
// --------------------------------------------------

/* 데이터 레이아웃 테이블 */
.data-layout-table {
	width: 100%;
	table-layout: fixed;
	line-height: 1.5;
	border: 0;

	&.auto {
		colgroup { display: block; }
		
		tr {
			display: block;
			width: 100%;
			text-align: center;
			font-size: 0;
		}

		th {
			display: inline-block;
			text-align: left;
			font-size: 16px;
			padding-right: 40px;
		}

		td {
			display: inline-block;
			text-align: left;
			font-size: 16px;
			padding-right: 40px;
		}
	}
	
	.table {
		width: 100%;
		table-layout: fixed;
		line-height: 1.5;
		background-color: #fff;
		border: 0 none;
	}

	th {
		box-sizing: border-box;
		padding-left: 14px;
		text-align: left;
		vertical-align: top;
		font-weight: 500;
		font-size: 16px;
		color: #232323;
		word-wrap: break-word;
		border: 0 none;
		position: relative;

		&:before {
			content: '';
			display: block;
			width: 3px;
			height: 3px;
			background: #666;
			position: absolute;
			top: 11px;
			left: 0;
		}
	}

	td {
		box-sizing: border-box;
		text-align: left;
		vertical-align: top;
		font-weight: 300;
		font-size: 16px;
		color: #666;
		word-wrap: break-word;
		border: 0 none;
	}

	tr:not(:last-child) {
		th, td {
			padding-bottom: 10px;
		}
	}
}