//
// 블록 탭메뉴
// --------------------------------------------------

/* 블록 탭메뉴 */
.tab-block {
	margin-bottom: 20px;
	text-align: center;
	font-size: 0;
	position: relative;

	@include clearfix;

	/* 마진 없이 사용 */
	&.no-margin { margin-bottom: -10px; }

	a {
		box-sizing: border-box;
		display: block;
		min-width: 150px;
		height: 50px;
		line-height: 48px;
		padding: 0 20px;
		text-align: center;
		font-size: 16px;
		color: #999;
		text-decoration: none;
		background: #fff;
		border: 1px solid #ddd;
	}

	.tab {
		display: inline-block;
		margin: 0 5px 10px;
		vertical-align: top;
		
		&.selected {
			a {
				color: #fff;
				background: #232323;
				border-color: #232323;
			}
		}
	}
}