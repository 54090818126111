//
// 게시판 목록 상단검색
// --------------------------------------------------

/* 게시판 목록 상단검색 */
.list-search-form {
	float: right;

	.search-opt {
		width: 100px;
		margin-right: 10px;
		vertical-align: top;
	}

	input.txt {
		font-size: 15px;
		width: 240px;
		margin-right: 10px;
		vertical-align: top;

		&::-webkit-input-placeholder { color: #999; }
		&:-moz-placeholder { color: #999; }
		&::-moz-placeholder { color: #999; }
		&:-ms-input-placeholder { color: #999; }
		&::placeholder { color: #999; }
	}

	.btn-submit {
		font-size: 15px;
		color: #fff;
		line-height: 40px;
		background-color: #000;
		padding: 0 24px;
	}
}